import { type Task } from "../../store/task/task.context";
import { type RxRecurringNaked } from "../rxdb/schemas/recurring.schema";

export const isRecurringTask = (
  taskOrRecurring: Task | RxRecurringNaked
): boolean => {
  return (
    !!(taskOrRecurring as RxRecurringNaked).recurring_type &&
    !!(taskOrRecurring as RxRecurringNaked).start_day
  );
};
