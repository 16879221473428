import InboxOutlinedIcon from "@mui/icons-material/InboxOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import { Box, Button, Typography } from "@mui/material";
import {
    type TmpTask,
} from "@structured/utils/tasks";
import { type Control, Controller, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface TaskTypeInputRowProps {
    disabled: boolean;
    task: TmpTask;
    setValue: UseFormSetValue<FormValues>;
    control: Control<FormValues>;
}
export const TaskTypeInputRow = (props: TaskTypeInputRowProps) => {
    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <WidgetsOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box
                sx={{
                    display: "flex",
                    width: "31.3125rem",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Controller
                    control={props.control}
                    name="task.is_in_inbox"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <Button
                            onClick={() => { onChange(false) }}
                            {...field}
                            fullWidth
                            disableElevation
                            variant={!value ? "contained" : "outlined"}
                            endIcon={<InboxOutlinedIcon />}
                            size="large"
                            sx={{ height: "3.125rem" }}
                        >
                            <Typography
                                fontSize="1rem"
                                fontWeight="500"
                            >
                                Planned
                            </Typography>
                        </Button>
                    )}
                />
                <Controller
                    control={props.control}
                    name="task.is_in_inbox"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <Button
                            onClick={() => { onChange(true) }}
                            {...field}
                            fullWidth
                            disableElevation
                            variant={value ? "contained" : "outlined"}
                            endIcon={<InboxOutlinedIcon />}
                            size="large"
                            sx={{ height: "3.125rem" }}
                        >
                            <Typography
                                fontSize="1rem"
                                fontWeight="500"
                            >
                                Inbox
                            </Typography>
                        </Button>
                    )}
                />

            </Box>
        </TaskFormRowContainer>
    );
}
