import { Box, Grid, Paper } from "@mui/material";
import appStoreBadge from "@structured/assets/signup-page-images/appStoreBadge.svg";
import logo from "@structured/assets/signup-page-images/logo.webp";
import playStoreBadge from "@structured/assets/signup-page-images/playStoreBadge.png";
import SignupPageLogo from "@structured/assets/signup-page-images/signup-page-logo.svg";
import transitionImage from "@structured/assets/signup-page-images/transitionImage.png";

import { BETABadgeComponent, FooterComponent, WelcomeToStructuredTextComponent } from "../LoginComponents";


interface TabletViewProps {
    gradient: string;
    loginForm: React.ReactNode;
    footerLinks: { title: string, link: string }[];
}
const TabletView = ({ gradient, loginForm, footerLinks }: TabletViewProps) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                minHeight: "100vh",
                background: gradient,
                position: "relative",
                overflow: "hidden"
            }}
        >
            <Box
                component="img"
                src={transitionImage}
                sx={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    userSelect: "none",
                    zIndex: 1,
                }}
            />
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    justifyContent: "space-between",
                    position: "relative",
                    zIndex: 2
                }}
            >
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <Box component="img" src={SignupPageLogo} alt="Structured" sx={{ margin: "1.875rem 0 0 2.5rem", zIndex: 2 }} />
                    <BETABadgeComponent inverse />
                </Box>
                <Paper
                    elevation={0}
                    sx={{
                        display: "flex",
                        width: "25.625rem",
                        padding: "1.875rem",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2.5rem",
                        borderRadius: "1rem",
                        boxShadow: "0rem 0.125rem 0.1875rem 0rem rgba(0, 0, 0, 0.12)",
                    }}
                >
                    <WelcomeToStructuredTextComponent gradient={gradient} logo={logo} />
                    <>
                        {loginForm}
                    </>
                </Paper>
                <FooterComponent
                    appStoreBadge={appStoreBadge}
                    playStoreBadge={playStoreBadge}
                    footerLinks={footerLinks}
                    textColor={"black"}
                />
            </Box>
        </Grid>
    );
}

export default TabletView;