import { useSettings } from "@structured/store";

import { type RxRecurringNaked } from "../rxdb";

interface Weekday {
  label: string;
  key: keyof RxRecurringNaked;
  i: number;
}

export const useSortedWeekdays = () => {
  const {
    settings: { first_weekday },
  } = useSettings();

  const weekdays = [
    { label: "Sun", key: "sunday", value: 0 },
    { label: "Mon", key: "monday", i: 1 },
    { label: "Tue", key: "tuesday", i: 2 },
    { label: "Wed", key: "wednesday", i: 3 },
    { label: "Thu", key: "thursday", i: 4 },
    { label: "Fri", key: "friday", i: 5 },
    { label: "Sat", key: "saturday", i: 6 },
  ].sort((a, b) => {
    const ac = a.value < first_weekday ? a.value + 7 : a.value;
    const bc = b.value < first_weekday ? b.value + 7 : b.value;
    return ac - bc;
  }) as Weekday[];

  return weekdays;
};
