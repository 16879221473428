import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import PublicIcon from "@mui/icons-material/Public";
import ReplayIcon from "@mui/icons-material/Replay";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import type { UseTaskResponse } from "@structured/utils/hooks";
import { useState } from "react";

import styles from "./TimelineTask.module.scss";

export const TaskSummary = ({
  task,
  subtasks,
  completedSubtasks,
  isRecurring,
  completedAt,
  formattedTime,
  formattedForeignTime,
  openEditModal,
}: UseTaskResponse) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      className={styles.TimelineTask__Content}
      onClick={(e) => {
        openEditModal();
        e.stopPropagation();
      }}
    >
      <Typography
        component="span"
        variant="body2"
        color="text.secondary"
        className={styles.TimelineTask__Time}
      >
        {formattedTime}
        &nbsp;
        {isRecurring && <ReplayIcon fontSize="inherit" />}
        {task.timezone && (
          <Box component="span">
            <Box
              component="span"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <PublicIcon fontSize="inherit" />
            </Box>

            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography
                padding="0.5rem"
                textAlign="center"
                variant="body2"
                color="text.secondary"
              >
                {formattedForeignTime}
              </Typography>
            </Popover>
          </Box>
        )}
      </Typography>

      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 700,
          color: completedAt ? "text.secondary" : "text.primary",
          opacity: completedAt ? 0.6 : 1,
          textDecoration: completedAt ? "line-through" : "none",
        }}
      >
        {task.title}
      </Typography>
      {subtasks.length > 0 && (
        <Typography
          component="span"
          color="text.secondary"
          className={styles.TimelineTask__Subtask}
        >
          {completedSubtasks.length === subtasks.length ? (
            <CheckBoxIcon />
          ) : (
            <CheckBoxOutlineIcon />
          )}{" "}
          {completedSubtasks.length}/{subtasks.length}
        </Typography>
      )}
    </Box>
  );
};
