import TimelineContent from "@mui/lab/TimelineContent";
import Typography from "@mui/material/Typography";
import { getThemeColor } from "@structured/utils/colors";
import type { TimelineGapMsgEntry } from "@structured/utils/timeline";
import dayjs from "dayjs";

import styles from "./TimelineGapMsg.module.scss";

export const TimelineGapMsg = (props: { entry: TimelineGapMsgEntry }) => {
  const entry = props.entry;
  const duration = dayjs.duration(entry.duration, "minute").format("m [min]");

  return (
    <TimelineContent>
      <div className={styles.TimelineGapMsg}>
        {entry.isInThePast ? (
          <Typography variant="body2" color="text.secondary">
            Interval over. What's next?
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            Need a break of&nbsp;
            <Typography
              display="contents"
              variant="body2"
              color={(theme) => getThemeColor(props.entry.color, theme)}
              fontWeight={600}
              component="em"
            >
              {duration}
            </Typography>
            ?
          </Typography>
        )}
      </div>
    </TimelineContent>
  );
};
