import { getIsoStrDateOnly } from "@structured/utils/date";
import { TableName } from "@structured/utils/rxdb";
import {
  type RxRecurringOccurrenceNaked,
  useDb,
  validateDocs,
} from "@structured/utils/rxdb";
import React, { createContext, useContext, useEffect, useState } from "react";
import { tap } from "rxjs";

interface OccurrenceContextProps {
  occurrences: RxRecurringOccurrenceNaked[];
  isLoading: boolean;
  isValid: boolean;
  addOccurrence: (occurrence: RxRecurringOccurrenceNaked) => Promise<void>;
  removeOccurrence: (id: string) => Promise<void>;
  removeOccurrences: (ids: string[]) => Promise<void>;
  updateOccurrence: (
    id: string,
    changes: Partial<RxRecurringOccurrenceNaked>
  ) => Promise<void>;
  getOccurrencesForRecurringTask: (
    recurringId: string
  ) => RxRecurringOccurrenceNaked[];
  getDailyOccurrenceForRecurringTask: (
    recurringId: string,
    day: Date
  ) => RxRecurringOccurrenceNaked;
}

const OccurrenceContext = createContext<OccurrenceContextProps>({
  occurrences: [],
  isLoading: true,
  isValid: true,
  addOccurrence: null,
  removeOccurrence: null,
  updateOccurrence: null,
  removeOccurrences: null,
  getDailyOccurrenceForRecurringTask: null,
  getOccurrencesForRecurringTask: null,
});

export const OccurrenceProvider: React.FC<{
  readonly children: React.ReactNode;
}> = ({ children }) => {
  const [occurrences, setOccurrences] = useState<RxRecurringOccurrenceNaked[]>(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const { db, isBooting: isFetching } = useDb();

  useEffect(() => {
    if (!isFetching && db) {
      const subscription = db.recurring_occurrence
        .find({ sort: [] })
        .$.pipe(tap(() => setIsLoading(true)))
        .subscribe((documents) => {
          setIsValid(validateDocs(documents, TableName.RecurringOccurrence));
          setOccurrences(documents.map((doc) => doc.toMutableJSON()));
          setIsLoading(false);
        });

      return () => subscription.unsubscribe();
    }
  }, [db, isFetching]);

  const cleanModel = <T extends Partial<RxRecurringOccurrenceNaked>>(
    original: T
  ): T => {
    const { received_at, replication_revision, ...clean } = original;

    return clean as T;
  };

  const addOccurrence = async (Occurrence: RxRecurringOccurrenceNaked) => {
    try {
      await db.recurring_occurrence.insert(cleanModel(Occurrence));
    } catch (e) {
      console.error(e);
    }
  };

  const removeOccurrences = async (ids: string[]) => {
    try {
      await db.recurring_occurrence.bulkRemove(ids);
    } catch (e) {
      console.error(e);
    }
  };

  const removeOccurrence = async (id: string) => {
    try {
      const doc = await db.recurring_occurrence.findOne(id).exec();
      if (doc) {
        await doc.remove();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateOccurrence = async (
    id: string,
    changes: Partial<RxRecurringOccurrenceNaked>
  ) => {
    try {
      const doc = await db.recurring_occurrence.findOne(id).exec();
      if (doc) {
        await doc.incrementalPatch(
          cleanModel({
            ...changes,
            modified_at: new Date().toISOString(),
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getOccurrencesForRecurringTask = (recurringId: string) => {
    return occurrences.filter(
      (occurrence) => occurrence.recurring === recurringId
    );
  };

  const getDailyOccurrenceForRecurringTask = (
    recurringId: string,
    day: Date
  ) => {
    const isoDate = getIsoStrDateOnly(day);

    return occurrences.find(
      (occurrence) =>
        occurrence.day === isoDate && occurrence.recurring === recurringId
    );
  };

  return (
    <OccurrenceContext.Provider
      value={{
        occurrences,
        isLoading,
        isValid,
        addOccurrence,
        removeOccurrence,
        removeOccurrences,
        updateOccurrence,
        getDailyOccurrenceForRecurringTask,
        getOccurrencesForRecurringTask,
      }}
    >
      {children}
    </OccurrenceContext.Provider>
  );
};

export const useOccurrences = () => useContext(OccurrenceContext);
