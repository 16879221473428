import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { ConfirmationModal } from "@structured/components/Modal";
import { deleteAllLocalData } from "@structured/utils/api";
import { type RxSettingsEntryNaked } from "@structured/utils/rxdb";
import { useState } from "react";

import styles from "../SettingsCmp.module.scss";

const WEEKDAYS: { label: string; key: string; value: number }[] = [
  { label: "System Default", key: "system", value: 0 },
  { label: "Sunday", key: "sunday", value: 1 },
  { label: "Monday", key: "monday", value: 2 },
  { label: "Tuesday", key: "tuesday", value: 3 },
  { label: "Wednesday", key: "wednesday", value: 4 },
  { label: "Thursday", key: "thursday", value: 5 },
  { label: "Friday", key: "friday", value: 6 },
  { label: "Saturday", key: "saturday", value: 7 },
];

export const SubSettingAdvanced = (props: {
  settings: RxSettingsEntryNaked;
  onUpdate: (upd: Partial<RxSettingsEntryNaked>) => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  return (
    <>
      <Box className={styles.Settings__Section}>
        <Typography className={styles.Settings__Label}>
          First day of the week:
        </Typography>
        <Select
          id="weekday-select"
          value={props.settings.first_weekday === undefined ? 0 : props.settings.first_weekday}
          onChange={(ev) =>
            props.onUpdate({ first_weekday: +ev.target.value })
          }
        >
          {WEEKDAYS.map((day) => (
            <MenuItem key={day.value} value={day.value}>
              {day.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box className={styles.Settings__Footer}>
        <Divider />
        <Button
          variant="text"
          onClick={() => setOpenModal(true)}
          startIcon={<DeleteOutlinedIcon />}
        >
          Reset App
        </Button>
        <Typography color="text.secondary" variant="body2">
          Resetting the app will permanently erase all settings and tasks.{" "}
          <Typography
            component="span"
            display="contents"
            color="primary"
            variant="body2"
          >
            This action is irreversible!
          </Typography>
        </Typography>
      </Box>
      <ConfirmationModal
        open={openModal}
        onCancel={() => setOpenModal(false)}
        onConfirm={async () => {
          await deleteAllLocalData();
        }}
        description="Are you sure you want to reset the app?"
      />
    </>
  );
};
