import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: +process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  tracePropagationTargets:
    process.env.REACT_APP_SENTRY_TRACING_ORIGINS.split(","),
  replaysOnErrorSampleRate: +process.env.REACT_APP_SENTRY_ERROR_SAMPLE_RATE,
  ignoreErrors: ["ResizeObserver loop limit exceeded"],
  autoSessionTracking: true,
});
