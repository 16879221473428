import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Icon } from "@structured/components/Icon";
import { ICON_CATEGORIES } from "@structured/utils/icons";
import React from "react";

import styles from "./IconPicker.module.scss";

export interface IconPickerHeaderProps {
  readonly selectedTab: string;
  readonly onTabSelect: (categoryId: string) => void;
}

export const IconPickerHeader: React.FC<IconPickerHeaderProps> = (props) => {
  return (
    <Tabs
      className={styles.IconPicker__Header}
      variant="scrollable"
      scrollButtons={false}
      value={props.selectedTab}
    >
      {ICON_CATEGORIES.map((category) => (
        <Tab
          key={category.id}
          value={category.id}
          sx={{ minWidth: "3rem" }}
          icon={<Icon name={category.iconMaterialId} />}
          onClick={() => props.onTabSelect(category.id)}
        />
      ))}
    </Tabs>
  );
};
