import { supabase } from "../supabase.client";

export const sendMagicLink = async (email: string) => {
  try {
    await supabase.auth.signInWithOtp({ email });
  } catch (e) {
    console.error(e);
  }
};

export const verifyOtp = async (email: string, otp: string) => {
  try {
    return await supabase.auth.verifyOtp({
      email,
      token: otp,
      type: "email",
    });
  } catch (e) {
    console.error(e);
  }
};
