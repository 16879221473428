import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@structured/components";
import type { Task } from "@structured/store";
import { getThemeColor } from "@structured/utils/colors";
import { useTask } from "@structured/utils/hooks";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import { TaskSummary } from "@structured/views/DayView/Timeline/TimelineTask/TaskSummary";
import React from "react";

import styles from "./InboxTask.module.scss";

export interface InboxTaskProps {
  readonly task: Task;
  readonly day: Date;
}

export const InboxTask: React.FC<InboxTaskProps> = (props) => {
  const taskContext = useTask(props.task, props.day);

  return (
    <Box
      onClick={(e) => {
        taskContext.openEditModal();
        e.stopPropagation();
      }}
      className={styles.InboxTask}
      sx={{
        "&:hover": {
          background: (theme) =>
            getThemeColor(taskContext.task.color, theme, "light"),
        },
        alignItems: taskContext.subtasks?.length ? "flex-start" : "center",
      }}
    >
      <Box className={styles.InboxTask__Content}>
        <Box
          className={styles.InboxTask__Icon}
          sx={{
            background: (theme) =>
              taskContext.completedAt
                ? getThemeColor(taskContext.task.color, theme)
                : theme.palette.divider,
          }}
        >
          <Icon
            fontSize="2rem"
            name={getMaterialIdFromSymbolId(taskContext.task.symbol)}
            sx={{
              color: (theme) =>
                taskContext.completedAt
                  ? getThemeColor(taskContext.task.color, theme, "contrastText")
                  : getThemeColor(taskContext.task.color, theme),
            }}
          />
        </Box>
        <TaskSummary {...taskContext} />
      </Box>
      <IconButton
        onClick={(e) => {
          taskContext.openEditModal({ is_in_inbox: false });
          e.stopPropagation();
        }}
      >
        <Add />
      </IconButton>
    </Box>
  );
};
