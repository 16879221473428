import ArrowBack from "@mui/icons-material/ArrowBack";
import Divider from "@mui/material/Divider";
import MuiDrawer, {
  type DrawerProps as MuiDrawerProps,
} from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import { useUI } from "@structured/store";
import React from "react";

export interface DrawerProps extends MuiDrawerProps {
  readonly header?: React.ReactNode;
}

const DrawerHeader = styled("div", {
  shouldForwardProp: (prop) => prop !== "hasContent",
})<{ hasContent: boolean }>(({ theme, hasContent }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: hasContent ? "space-between" : "flex-end",
}));

export const Drawer: React.FC<DrawerProps> = (props) => {
  const {
    drawer: { isOpen, width },
    toggleIsDrawerOpen,
  } = useUI();

  return (
    <MuiDrawer
      sx={{
        width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width,
          boxSizing: "border-box",
          background: (theme) => theme.palette.background.default,
        },
      }}
      variant="persistent"
      anchor="left"
      open={isOpen}
    >
      <DrawerHeader sx={{ padding: "1.75rem" }} hasContent={!!props.header}>
        {props.header}
        <IconButton onClick={() => toggleIsDrawerOpen()} size="small">
          <ArrowBack fontSize="inherit" />
        </IconButton>
      </DrawerHeader>
      <Divider />
      {props.children}
    </MuiDrawer>
  );
};
