import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Dialog, { type DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React from "react";

export interface ModalProps extends DialogProps {
  readonly header?: React.ReactNode | false;
  readonly actions?: React.ReactNode;
}

export const ModalWrapper: React.FC<ModalProps> = ({
  children,
  actions,
  header,
  ...props
}) => {
  const handleClose = (e: React.MouseEvent<HTMLSpanElement>) => {
    props.onClose(e, "backdropClick");
  };

  return (
    <Dialog {...props} open={props.open}>
      {children && <DialogContent>{children}</DialogContent>}
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
};
