import {
  RECEIVED_AT_FIELD,
  type RxGenericReplicationDocumentNaked,
} from "../rxdb.types";

const PROPS_TO_CHECK: (keyof RxGenericReplicationDocumentNaked)[] = [
  "user_id",
  RECEIVED_AT_FIELD,
  "replication_revision",
];

type ExtendedDocType<T> = T & RxGenericReplicationDocumentNaked;

export const checkValidReplicationDoc = <DT>(
  doc: DT | {}
): ExtendedDocType<DT> => {
  const docKeys: string[] = Object.keys(doc as {});
  if (!PROPS_TO_CHECK.every((prop) => docKeys.includes(prop))) {
    console.log({ doc });
    throw new Error("Replication doc validation failed");
  }
  return doc as ExtendedDocType<DT>;
};
