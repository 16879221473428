import Box, { type BoxProps } from "@mui/material/Box";
import { useUI } from "@structured/store";
import React from "react";

export const Content: React.FC<BoxProps> = (props) => {
  const {
    drawer: { isOpen, width },
  } = useUI();
  return (
    <Box
      sx={{
        flexGrow: 1,
        transition: (theme) =>
          theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        marginInlineStart: 0,
        ...(isOpen && {
          transition: (theme) =>
            theme.transitions.create("margin", {
              easing: theme.transitions.easing.easeOut,
              duration: theme.transitions.duration.enteringScreen,
            }),
          marginInlineStart: `${width}px`,
        }),
      }}
      {...props}
    >
      {props.children}
    </Box>
  );
};
