import dayjs from "dayjs";

import {
  type TimelineEntry,
  TimelineEntryType,
} from "../timeline/timeline.types";

export const getFirstFreePeriodStart = (
  timelineEntries: TimelineEntry[],
  periodDurationInMin: number,
  targetDay: Date,
  now: Date = new Date()
): Date => {
  const isTargetDayToday = dayjs(targetDay).diff(now, "day") === 0;

  if (timelineEntries.length === 0) {
    const nd = new Date(targetDay);
    if (isTargetDayToday) {
      nd.setHours(nd.getHours() + 1, 0, 0, 0);
    } else {
      nd.setHours(9, 0, 0, 0);
    }
    return nd;
  }
  const firstGapItem = timelineEntries.find(
    (entry) =>
      (entry.type === TimelineEntryType.GAP_MSG ||
        entry.type === TimelineEntryType.ADD_TASK_PROMPT) &&
      entry.duration >= periodDurationInMin &&
      (!isTargetDayToday ||
        entry.start >= now ||
        (entry.end.getTime() - now.getTime()) / 60000 > periodDurationInMin)
  );

  if (firstGapItem) {
    if (firstGapItem.start < now) {
      return now;
    }
    return firstGapItem.start;
  }

  return timelineEntries[timelineEntries.length - 1].end;
};
