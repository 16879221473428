import { resetDatabase } from "../../rxdb/db";
import { supabase } from "../supabase.client";

export async function deleteAllLocalData() {
  await resetDatabase();
}

export async function deleteAllLocalDataAndLogout() {
  await deleteAllLocalData();
  await supabase.auth.signOut({ scope: "local" });

  /*
  NOTE: we resort to reloading since it is the easiest and cleanest way to reset the whole app and
  avoiding memory leaks etc.
   */
  window.location.reload();
}
