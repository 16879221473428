export const DarkTheme = () => (
  <svg viewBox="0 0 104 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="104" height="64" fill="#242424" />
    <rect
      x="4.52222"
      y="9.11108"
      width="18.087"
      height="4.06349"
      rx="2.03175"
      fill="#F88E87"
    />
    <rect
      x="27.1304"
      y="19.2222"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F88E87"
    />
    <rect
      x="73.4778"
      y="19.2222"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#5C5C5C"
    />
    <rect
      x="73.4778"
      y="29.381"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#5C5C5C"
    />
    <rect
      x="73.4778"
      y="39.5397"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#5C5C5C"
    />
    <rect
      x="73.4778"
      y="49.6985"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#5C5C5C"
    />
    <rect
      x="27.1304"
      y="29.381"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F88E87"
    />
    <rect
      x="27.1304"
      y="39.5397"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F88E87"
    />
    <rect
      x="27.1304"
      y="49.6985"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F88E87"
    />
    <rect
      x="27.1304"
      y="9.11108"
      width="71.2174"
      height="4.06349"
      rx="2.03175"
      fill="#5C5C5C"
    />
  </svg>
);
