import { useUI } from "@structured/store";

import { TaskAddModal } from "../TaskAddModal/TaskAddModal";
import { TaskContextModal } from "../TaskContextModal/TaskContextModal";

export const GlobalModals = () => {
  const {
    contextModalTaskOrRecurring,
    isShowAddTaskModal,
    setIsShowAddTaskModal,
    setContextModalTask,
  } = useUI();

  return (
    <>
      {isShowAddTaskModal && (
        <TaskAddModal
          onClose={() => setIsShowAddTaskModal(false)}
        ></TaskAddModal>
      )}

      {!!contextModalTaskOrRecurring && (
        <TaskContextModal
          taskOrRecurring={contextModalTaskOrRecurring.taskOrRecurring}
          dayTimestamp={contextModalTaskOrRecurring.dayTimestamp}
          onClose={() => setContextModalTask(null)}
        />
      )}
    </>
  );
};
