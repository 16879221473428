import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloudIcon from "@mui/icons-material/Cloud";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ConfirmationModal } from "@structured/components/Modal";
import { useSession, useUI } from "@structured/store";
import { deleteAllLocalDataAndLogout, supabase } from "@structured/utils/api";
import { type RxSettingsEntryNaked,TableName } from "@structured/utils/rxdb";
import { useState } from "react";

import styles from "../SettingsCmp.module.scss";

export const SubSettingSync = (props: {
  settings: RxSettingsEntryNaked;
  triggerResync: () => void;
}) => {
  const session = useSession();
  const { isSyncing } = useUI();

  const [openModal, setOpenModal] = useState<"logout" | "delete" | false>(
    false
  );

  return (
    <>
      <Box className={styles.Settings__Section}>
        <Box>
          <Box className={styles.Settings__Label}>
            <Typography color="text.secondary" variant="caption">
              Structured Cloud Sync Status
            </Typography>
            <Button variant="text" size="small" onClick={props.triggerResync}>
              <Typography variant="caption">Resync</Typography>
            </Button>
          </Box>
          <Typography
            sx={{ borderColor: (theme) => theme.palette.divider }}
            className={styles.Settings__SyncStatus}
          >
            {isSyncing ? (
              <>
                <CloudIcon /> Fully synced
              </>
            ) : (
              <>
                <CloudQueueIcon /> Sync in progress
              </>
            )}
          </Typography>
          <Typography color="text.secondary" variant="caption">
            If you need troubleshooting tips, please check the{" "}
            <Link href="https://help.structured.app/" target="_blank">
              Help Center
            </Link>
            .
          </Typography>
        </Box>
        <Box>
          <Box className={styles.Settings__Label}>
            <Typography color="text.secondary" variant="caption">
              Account email
            </Typography>
          </Box>
          <TextField
            type="email"
            InputProps={{ disabled: true }}
            value={session.user.email}
            fullWidth
          />
        </Box>
        <Box>
          <Box className={styles.Settings__Label}>
            <Typography color="text.secondary" variant="caption">
              Sync ID
            </Typography>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                void navigator.clipboard.writeText(session.user.id);
              }}
            >
              <Typography variant="caption">Copy ID</Typography>
            </Button>
          </Box>
          <TextField
            type="text"
            InputProps={{ readOnly: true }}
            value={session.user.id}
            fullWidth
          />
        </Box>
      </Box>
      <Box className={styles.Settings__Footer}>
        <Divider />
        <Button
          variant="text"
          color="warning"
          startIcon={<LogoutOutlinedIcon />}
          onClick={() => setOpenModal("logout")}
        >
          Logout
        </Button>
        <Button
          variant="text"
          color="error"
          startIcon={<CancelOutlinedIcon />}
          onClick={() => setOpenModal("delete")}
        >
          Delete Account
        </Button>
      </Box>
      <ConfirmationModal
        open={openModal === "logout"}
        onCancel={() => setOpenModal(false)}
        onConfirm={() => {
          void deleteAllLocalDataAndLogout();
        }}
        description="Are you sure you want to logout?"
      />
      <ConfirmationModal
        open={openModal === "delete"}
        onCancel={() => setOpenModal(false)}
        onConfirm={async () => {
          for (const key in TableName) {
            await supabase
              .from(TableName[key])
              .delete()
              .eq("user_id", session.user.id);
          }

          await deleteAllLocalDataAndLogout();
        }}
        description="Are you sure you want to delete your account?"
      />
    </>
  );
};
