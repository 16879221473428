import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CloseIcon from "@mui/icons-material/Close";
import RepeatOutlinedIcon from "@mui/icons-material/RepeatOutlined";
import { Box, Button, FormControl, Icon, IconButton, MenuItem, Select } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { getIsoStrDateOnly } from "@structured/utils/date";
import {
  useSelectedWeekdays,
  useSortedWeekdays,
} from "@structured/utils/hooks";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import {
  type RecurringOwnFields,
  RecurringType,
} from "@structured/utils/tasks";
import dayjs from "dayjs";
import React from "react";

interface RecurringOptionsProps {
  disabled: boolean;
  initialStartDayIfTask: Date;
  recurringCfg: RxRecurringNaked | RecurringOwnFields | null;
  onUpdate: (update: RecurringOwnFields | null) => void;
}

export const RecurringOptions: React.FC<RecurringOptionsProps> = (props) => {
  const startDate = new Date(
    props.recurringCfg?.start_day ?? props.initialStartDayIfTask
  );

  const recurringType =
    props.recurringCfg?.recurring_type ?? RecurringType.Once;

  const [selectedWeekdays, setSelectedWeekdays] = useSelectedWeekdays(
    props.recurringCfg as RxRecurringNaked
  );

  const sortedWeekdays = useSortedWeekdays();

  const handleWeekdaysChange = (weekdays: string[]) => {
    setSelectedWeekdays(weekdays);

    const mappedDays = weekdays.reduce(
      (prev, weekday) => ({
        ...prev,
        [weekday]: true,
      }),
      {}
    );

    props.onUpdate({
      ...props.recurringCfg,
      ...sortedWeekdays.reduce(
        (prev, weekday) => ({
          ...prev,
          [weekday.key]: false,
        }),
        {}
      ),
      ...mappedDays,
    });
  };

  const handleRecurringTypeChange = (type: RecurringType) => {
    if (type === RecurringType.Once) {
      props.onUpdate(null);
    } else {
      let update: RecurringOwnFields = {
        ...props.recurringCfg,
        start_day:
          props.recurringCfg?.start_day ?? getIsoStrDateOnly(startDate),
        end_day: props.recurringCfg?.end_day ?? null,
        interval: props.recurringCfg?.interval ?? 1,
        recurring_type: type,
      };

      if (type === RecurringType.Weekly) {
        const currentSelectedDay = dayjs(startDate).day();
        const day = sortedWeekdays[currentSelectedDay].key;
        update = {
          ...update,
          [day]: true,
        };
        setSelectedWeekdays([day]);
      }

      props.onUpdate(update);
    }
  };

  const handleStartDateChange = (startDate: Date) => {
    props.onUpdate({
      ...props.recurringCfg,
      start_day: getIsoStrDateOnly(startDate),
    });
  };

  const handleEndDateChange = (endDate: Date) => {
    props.onUpdate({
      ...props.recurringCfg,
      end_day: getIsoStrDateOnly(endDate),
    });
  };

  const handleIntervalChange = (interval: number) => {
    props.onUpdate({
      ...props.recurringCfg,
      interval,
    });
  };

  const handleClearEndDate = () => {
    props.onUpdate({
      ...props.recurringCfg,
      end_day: null
    });
  };

  const OPTIONS = [
    { label: "Once", val: RecurringType.Once, repeatLabel: "NONE" },
    { label: "Daily", val: RecurringType.Daily, repeatLabel: "Day" },
    { label: "Weekly", val: RecurringType.Weekly, repeatLabel: "Week" },
    { label: "Monthly", val: RecurringType.Monthly, repeatLabel: "Month" },
  ];

  const repeatLabel: string = OPTIONS.find(
    (opt) => opt.val === recurringType
  )?.repeatLabel;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "top",
          gap: "1rem",
          alignSelf: "stretch",
        }}
      >
        <IconButton
          sx={{
            display: "flex",
            width: "3.125rem",
            height: "3.125rem",
            padding: "1rem",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.625rem",
            borderRadius: "0.5rem",
            border: "0",
            cursor: "default"
          }}
          disableRipple
          disabled={props.disabled}
        >
          <RepeatOutlinedIcon
            sx={{
              color: "action",
              flexShrink: 0,
              width: "1.25rem",
              height: "1.25rem",
            }}
          />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "0.5rem",
            alignSelf: "stretch",
          }}
        >
          <FormControl
            fullWidth
            sx={{
              height: "3.125rem",
              borderRadius: "0.5rem 0rem 0rem 0rem",
              border: "0.0625rem 0rem 0rem 0rem",
            }}
          >
            <Select
              labelId="recurring-period-label"
              id="recurring-period"
              onChange={(e) => handleRecurringTypeChange(e.target.value as RecurringType)}
              color="primary"
              aria-label="Recurring option"
              value={recurringType}
              disabled={props.disabled}
              sx={{ width: "15.625rem", }}
            >
              {OPTIONS.map((opt) => (
                <MenuItem key={opt.label} value={opt.val}>
                  {opt.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {recurringType !== RecurringType.Once && (
            <>
              <Box sx={{ display: "flex", gap: "0.5rem", justifyContent: "start" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.125rem" }}>
                    <Typography>Start</Typography>
                  </Box>
                  <DatePicker
                    slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                      openPickerIcon: { fontSize: "inherit" },
                      openPickerButton: { size: "small" },
                    }}
                    disabled={props.disabled}
                    value={dayjs(getIsoStrDateOnly(startDate))}
                    onChange={(ev) => handleStartDateChange(ev.toDate())}
                  />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.125rem" }}>
                    <Typography>End</Typography>
                    {props.recurringCfg?.end_day && (
                      <Button
                        disabled={props.disabled}
                        size="small"
                        sx={{ display: "flex", gap: "0.25rem", padding: "0rem" }}
                        onClick={handleClearEndDate}
                      >
                        <CloseIcon fontSize="small" />
                        <Typography
                          fontSize="0.75rem"
                          fontWeight="400"
                          lineHeight="1.25rem"
                        >
                          Clear
                        </Typography>
                      </Button>
                    )}
                  </Box>
                  <DatePicker
                    slots={{ openPickerIcon: CalendarMonthOutlinedIcon }}
                    slotProps={{
                      textField: { size: "small", fullWidth: true },
                      openPickerIcon: { fontSize: "inherit" },
                      openPickerButton: { size: "small" },
                    }}
                    value={
                      props.recurringCfg?.end_day &&
                      dayjs(props.recurringCfg?.end_day)
                    }
                    disabled={props.disabled}
                    onChange={(ev) => handleEndDateChange(ev.toDate())}
                  />
                </Box>
              </Box>
              {recurringType === RecurringType.Weekly && (
                <Box sx={{ display: "flex", gap: "0.75rem", width: "100%", paddingTop: "1rem" }}>
                  {sortedWeekdays.map((weekday) => (
                    <Button
                      disableElevation
                      fullWidth
                      key={weekday.label}
                      variant={selectedWeekdays.includes(weekday.key) ? "contained" : "outlined"}
                      onClick={() => {
                        const newSelectedWeekdays = selectedWeekdays.includes(weekday.key)
                          ? selectedWeekdays.filter(day => day !== weekday.key)
                          : [...selectedWeekdays, weekday.key];
                        handleWeekdaysChange(newSelectedWeekdays);
                      }}
                      disabled={props.disabled}
                      sx={{ minWidth: 0, padding: "0.375rem 0.5rem" }}
                    >
                      {weekday.label}
                    </Button>
                  ))}
                </Box>
              )}
              <Box
                width={1}
                sx={{ paddingTop: "1rem", paddingBottom: "1rem" }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.125rem" }}>
                  <Typography>Repeat every</Typography>
                </Box>
                <TextField
                  type="number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {`${repeatLabel}${props.recurringCfg?.interval > 1 ? "s" : ""}`}
                      </InputAdornment>
                    ),
                  }}
                  inputMode="numeric"
                  value={props.recurringCfg?.interval ?? 1}
                  disabled={props.disabled}
                  onChange={(e) => {
                    if (e.target.value === "" || parseInt(e.target.value, 10) > 0) {
                      handleIntervalChange(parseInt(e.target.value, 10))
                    }
                  }}
                />
              </Box>
            </>
          )}
        </Box>
      </Box>
    </>
  );
};
