import { TaskType } from "@structured/utils/common";
import { getIsoStrDateOnly } from "@structured/utils/date";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import { RecurringType } from "@structured/utils/tasks";

type RecurringNakedWithoutSome = Omit<
    RxRecurringNaked,
    "id" | "user_id" | "created_at" | "modified_at" | "start_time" | "day"
>;
const SHARED: Omit<
    RecurringNakedWithoutSome,
    "title" | "symbol" | "color" | "type" | "energy_level"
> = {
    recurring_type: RecurringType.Daily,
    interval: 1,
    duration: 1,
    start_day: getIsoStrDateOnly(new Date()),
    end_day: null,
    monday: true,
    tuesday: true,
    wednesday: true,
    thursday: true,
    friday: true,
    saturday: true,
    sunday: true,
    replication_revision: "",
    is_symbol_set: false,
    is_all_day: false,
    note: "",
    _deleted: false,
    timezone: null,
    subtasks: [],
    alerts: [],
};

export const ONBOARDING_TASK_WAKE_UP: RecurringNakedWithoutSome = {
    ...SHARED,
    title: "Wake up",
    symbol: "sun.max.fill",
    color: "day",
    type: TaskType.RecurringMorning,
};

export const ONBOARDING_TASK_SLEEP: RecurringNakedWithoutSome = {
    ...SHARED,
    title: "Go to bed",
    symbol: "moon.fill",
    // TODO add correct color name
    color: "night",
    type: TaskType.RecurringNight,
};