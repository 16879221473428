import {
  type ExtractDocumentTypeFromTypedRxJsonSchema,
  type RxJsonSchema,
  toTypedRxJsonSchema,
} from "rxdb";

import { DATE_STR_LENGTH } from "../rxdb.const";

export const RECURRING_OCCURRENCE_SCHEMA_LITERAL = {
  title: "recurring occurrence schema",
  version: 1,
  primaryKey: "id",
  type: "object",
  properties: {
    // STANDARD REPLICATION FIELDS
    id: {
      type: "string",
      minLength: 8,
      maxLength: 100,
    },
    schema_version: {
      type: "integer",
    },
    replication_revision: {
      type: "string",
      minLength: 8,
    },
    received_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    // OTHER STANDARD FIELDS
    user_id: {
      type: "string",
    },
    created_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    modified_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    _deleted: {
      type: "boolean",
    },

    // OWN FIELDS
    recurring: {
      type: "string",
    },
    detached_task: {
      type: ["string", "null"],
    },
    completed_at: {
      type: ["string", "null"],
    },
    day: {
      type: "string",
    },
    order_index: {
      type: ["integer", "null"],
    },
    is_detached: {
      type: "boolean",
    },
    subtask_occurrences: {
      type: ["array", "null"],
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
          subtask: {
            type: "string",
          },
          completed_at: {
            type: ["string", "null"],
          },
        },
      },
    },
  },

  required: [
    "id",
    "user_id",
    "replication_revision",
    "created_at",
    "modified_at",
    "is_detached",
    // NOTE: we make all fields required, to make our life easier with the server communication
    // they are usually not required, but get a default value on the server so read model is practically
    // different from write model, but this gets confusing
    // NOTE2: received_at is the only exception, since we should avoid ever setting this on the client
    // "received_at",
    "_deleted",
    "completed_at",
    "subtask_occurrences",
    "day",
    "order_index",
    "recurring",
    "detached_task",
  ],
  indexes: [],
} as const;

const SCHEMA_TYPED = toTypedRxJsonSchema(RECURRING_OCCURRENCE_SCHEMA_LITERAL);
export type RxRecurringOccurrenceNaked =
  ExtractDocumentTypeFromTypedRxJsonSchema<typeof SCHEMA_TYPED>;
// TODO as any stuff required until merged: https://github.com/pubkey/rxdb/pull/4579
export const RECURRING_OCCURRENCE_SCHEMA: RxJsonSchema<RxRecurringOccurrenceNaked> =
  RECURRING_OCCURRENCE_SCHEMA_LITERAL as any as RxJsonSchema<RxRecurringOccurrenceNaked>;
