import { Divider } from "@mui/material";
interface DottedDividerProps {
  sx?: object;
}
export const DottedDivider = ({ sx }: DottedDividerProps) => {
  return (
    <Divider
      sx={{
        width: "100%",
        borderStyle: "dotted",
        borderColor: (theme) => theme.palette.action.active,
        ...sx,
      }}
    />
  );
};
