import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/Inbox";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ButtonDatePicker } from "@structured/components/ButtonDatePicker";
import {
  useOccurrences,
  useRecurringTasks,
  useSettings,
  useTasks,
} from "@structured/store";
import { useUI } from "@structured/store/ui/ui.provider";
import { resyncBackend } from "@structured/utils/api";
import { getIsoStrDateOnly, getWeekDayName } from "@structured/utils/date";
import { getDailyRecurringTasks } from "@structured/utils/tasks";
import { SettingsCmp } from "@structured/views/Settings/SettingsCmp";
import dayjs from "dayjs";
import weekday from "dayjs/plugin/weekday";
import React, { useEffect, useMemo, useState } from "react";

import { SelectDay, type SelectDayProps } from "../SelectDay/SelectDay";
import styles from "./SelectDayBar.module.scss";

dayjs.extend(weekday)

export const SelectDayBar = (props: {
  selectedDay: Date;
  onDaySelect: (day: Date) => void;
}) => {
  const [numberOfDays, setNumberOfDays] = useState(7);
  const [adjustedSelectedDay, setAdjustedSelectedDay] = useState(props.selectedDay);
  const locales = "en-US";
  const { getVisibleTasks } = useTasks();
  const { recurringTasks } = useRecurringTasks();
  const { occurrences } = useOccurrences();
  const {
    settings: { first_weekday: firstDayOfWeek },
  } = useSettings();
  const {
    drawer: { isOpen, width },
    toggleIsDrawerOpen,
  } = useUI();

  useEffect(() => {
    const resizeListener = () => {
      const totalWidth = window.innerWidth - (isOpen ? width : 0);
      const newNumberOfDays = Math.floor(totalWidth / 140);

      if (newNumberOfDays !== numberOfDays) {
        setNumberOfDays(Math.floor(totalWidth / 140));
      }
    };

    resizeListener();

    window.addEventListener("resize", resizeListener);

    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [numberOfDays, isOpen, width]);

  useEffect(() => {
    if (firstDayOfWeek === undefined) {
      const day = new Date();
      const currentDayOfWeek = day.getDay();
      const diff = currentDayOfWeek - getStardDayOfTheWeekLocal() + 1;
      day.setDate(day.getDate() - diff);
      setAdjustedSelectedDay(day);
      return
    }
    const day = new Date(props.selectedDay);
    const currentDayOfWeek = day.getDay();
    const diff = currentDayOfWeek - firstDayOfWeek + 1;
    day.setDate(day.getDate() - diff + (firstDayOfWeek === 0 ? getStardDayOfTheWeekLocal() : 0));
    setAdjustedSelectedDay(day);
  }, [props.selectedDay, firstDayOfWeek]);

  const getStardDayOfTheWeekLocal = () => {
    const day = dayjs().weekday(0);
    const dayName = day.format("dddd");
    if (dayName === "Sunday") return 1;
    if (dayName === "Monday") return 2;
  }

  const daysInWeek: Omit<SelectDayProps, "onDaySelect">[] = useMemo(
    () =>
      [...Array(numberOfDays)].map((_, i) => {
        const day = new Date(adjustedSelectedDay);
        day.setDate(day.getDate() + i);
        const isoDate = getIsoStrDateOnly(day);
        const tasksForDay = getVisibleTasks().filter(
          (t) => t.localDay === isoDate
        );
        const recurringForDay = getDailyRecurringTasks(
          recurringTasks,
          occurrences,
          isoDate
        );
        const allTasksForDay = [...tasksForDay, ...recurringForDay].slice(0, 5);

        return {
          date: day,
          dayNr: day.getDate(),
          title: getWeekDayName(day),
          isSelected: day.getDate() === props.selectedDay.getDate(),
          tasksForDay: allTasksForDay,
        };
      }),
    [numberOfDays, adjustedSelectedDay, getVisibleTasks, props.selectedDay, recurringTasks, occurrences]
  );

  const goPrevWeek = () => {
    const newDate = new Date(props.selectedDay);
    newDate.setDate(newDate.getDate() - 7);
    props.onDaySelect(newDate);
  };

  const goNextWeek = () => {
    const newDate = new Date(props.selectedDay);
    newDate.setDate(newDate.getDate() + 7);
    props.onDaySelect(newDate);
  };

  return (
    <Box className={styles.SelectDayBar}>
      <Box className={styles.SelectDayBar__Week}>
        <Box className={styles.SelectDayBar__Actions}>
          {!isOpen && (
            <IconButton color="primary" onClick={() => toggleIsDrawerOpen()}>
              <InboxIcon />
            </IconButton>
          )}

          <IconButton color="primary" onClick={() => goPrevWeek()}>
            <ChevronLeftIcon />
          </IconButton>

          <Typography
            variant="h3"
            fontWeight={700}
            sx={{ cursor: "pointer" }}
            onClick={() => props.onDaySelect(new Date())}
          >
            {props.selectedDay.toLocaleDateString(locales, { month: "long" })}
            &nbsp;
            <Typography display="inline" variant="inherit" color="primary">
              {props.selectedDay.toLocaleDateString(locales, {
                year: "numeric",
              })}
            </Typography>
          </Typography>

          <IconButton color="primary" onClick={() => goNextWeek()}>
            <ChevronRightIcon />
          </IconButton>

          <Chip
            color="primary"
            sx={{ borderRadius: "0.5rem" }}
            label={
              <Typography variant="h3" color="primary.contrastText">
                Beta
              </Typography>
            }
          />
        </Box>
        <Box className={styles.SelectDayBar__Actions}>
          <ButtonDatePicker
            value={dayjs(props.selectedDay)}
            onChange={(ev) => props.onDaySelect(ev.toDate())}
          />

          <SettingsCmp triggerResync={resyncBackend} />
        </Box>
      </Box>
      <Box paddingY="1.75rem" className={styles.SelectDayBar__Days}>
        {daysInWeek.map((day) => (
          <SelectDay
            {...day}
            key={day.date.getTime()}
            onDaySelect={props.onDaySelect}
          ></SelectDay>
        ))}
      </Box>
    </Box>
  );
};