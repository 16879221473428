import {
  OccurrenceProvider,
  RecurringProvider,
  TaskProvider,
} from "@structured/store";
import {
  Auth,
  Login,
  TermsOfService,
} from "@structured/views/Auth";
import { Main } from "@structured/views/Main";
import { createBrowserRouter } from "react-router-dom";

import { ProtectedRoute } from "./ProtectedRoute";
import { ProtectedWithTermsRoute } from "./ProtectedWithTermsRoute";
import { UnprotectedRoute } from "./UnprotectedRoute";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedWithTermsRoute>
        <TaskProvider>
          <OccurrenceProvider>
            <RecurringProvider>
              <Main />
            </RecurringProvider>
          </OccurrenceProvider>
        </TaskProvider>
      </ProtectedWithTermsRoute>
    ),
  },
  {
    path: "auth",
    element: <Auth />,
    children: [
      {
        path: "login",
        element: (
          <UnprotectedRoute>
            <Login />
          </UnprotectedRoute>
        ),
      },
      {
        path: "terms-of-service",
        element: (
          <ProtectedRoute>
            <TaskProvider>
              <OccurrenceProvider>
                <RecurringProvider>
                <TermsOfService />
                </RecurringProvider>
              </OccurrenceProvider>
            </TaskProvider>
          </ProtectedRoute>
        ),
      },
    ],
  },
]);