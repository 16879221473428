import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { type Task,useTasks, useUI } from "@structured/store";
import React, { useState } from "react";

import { InboxTask } from "./InboxTask/InboxTask";
import styles from "./InboxTasks.module.scss";

enum TabValue {
  All = "all",
  Incomplete = "incomplete",
  Complete = "complete",
}

export const InboxTasks: React.FC = () => {
  const { selectedDay } = useUI();
  const { getInboxTasks } = useTasks();
  const incompleteInboxTasks = getInboxTasks("incomplete");
  const completeInboxTasks = getInboxTasks("complete");

  const [tab, setTab] = useState<TabValue>(TabValue.All);

  const sortTasks = (tasks: Task[]) => {
    return tasks.sort((a, b) => {
      if (a.order_index !== b.order_index) {
        return a.order_index - b.order_index;
      }
      return (
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      );
    });
  };

  const sortedIncompleteTasks = sortTasks(incompleteInboxTasks);
  const sortedCompleteTasks = sortTasks(completeInboxTasks);

  return (
    <Box>
      <TabContext value={tab}>
        <TabList
          onChange={(_, value: TabValue) => setTab(value)}
          variant="fullWidth"
          scrollButtons="auto"
        >
          <Tab label="All" value={TabValue.All} />
          <Tab
            label="Incomplete"
            disabled={!incompleteInboxTasks?.length}
            value={TabValue.Incomplete}
          />
          <Tab
            label="Complete"
            disabled={!completeInboxTasks?.length}
            value={TabValue.Complete}
          />
        </TabList>
        <TabPanel className={styles.InboxTask__TabContent} value={TabValue.All}>
          {[...sortedIncompleteTasks, ...sortedCompleteTasks].map((task) => (
            <InboxTask key={task.id} task={task} day={selectedDay} />
          ))}
        </TabPanel>
        <TabPanel
          className={styles.InboxTask__TabContent}
          value={TabValue.Incomplete}
        >
          {sortedIncompleteTasks.map((task) => (
            <InboxTask key={task.id} task={task} day={selectedDay} />
          ))}
        </TabPanel>
        <TabPanel
          className={styles.InboxTask__TabContent}
          value={TabValue.Complete}
        >
          {sortedCompleteTasks.map((task) => (
            <InboxTask key={task.id} task={task} day={selectedDay} />
          ))}
        </TabPanel>
      </TabContext>
    </Box>
  );
};
