import Box from "@mui/material/Box";
import { Icon } from "@structured/components/Icon";
import type { IconListEntry } from "@structured/utils/icons";
import classNames from "classnames";
import React from "react";

import styles from "./IconPicker.module.scss";

export interface IconPickerEntryProps {
  readonly icon: IconListEntry;
  readonly isSelected: boolean;
  readonly onSelect: () => void;
}

export const IconPickerEntry: React.FC<IconPickerEntryProps> = (props) => {
  return (
    <Box
      onClick={() => props.onSelect()}
      id={props.icon.id}
      title={props.icon.name}
      className={classNames(styles.IconPicker__Entry, "IconPicker__Entry")}
      sx={{
        "&:hover": {
          backgroundColor: (theme) => theme.palette.primary.main,
        },
        backgroundColor: (theme) =>
          props.isSelected ? theme.palette.primary.main : theme.palette.divider,
      }}
    >
      <Icon
        sx={{
          ".IconPicker__Entry:hover &": {
            color: (theme) => theme.palette.primary.contrastText,
          },
          color: (theme) =>
            props.isSelected
              ? theme.palette.primary.contrastText
              : theme.palette.text.primary,
        }}
        name={props.icon.materialId}
      />
    </Box>
  );
};
