import "./App.scss";

import { Box } from "@mui/material";
import * as Sentry from "@sentry/react";
import { RouterProvider } from "react-router-dom";

import { router } from "./router/router";
import { SentryUserContext } from "./sentry/sentry-user-context";
import { RecurringProvider } from "./store";
import { TelemetryProvider } from "./telemetry";
import { useReplicationSyncChecker } from "./utils/hooks";

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<>An error has occurred</>}>
      <SentryUserContext />
      <TelemetryProvider>
        <Box className="App"> 
          <RouterProvider router={router} />
        </Box>
      </TelemetryProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
