import * as Sentry from "@sentry/react";
import { useSession } from "@structured/store";
import type React from "react";
import { useEffect } from "react";

export const SentryUserContext: React.FC = () => {
  const session = useSession();

  useEffect(() => {
    if (session) {
      Sentry.setUser({
        id: session.user.id,
        email: session.user.email,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [session]);

  return null;
};
