import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ColorSelector } from "@structured/components";
import { DarkTheme } from "@structured/components/ThemeSvg/DarkTheme";
import { LightTheme } from "@structured/components/ThemeSvg/LightTheme";
import { SystemTheme } from "@structured/components/ThemeSvg/SystemTheme";
import { type ThemeMode } from "@structured/utils/colors";
import { type RxSettingsEntryNaked } from "@structured/utils/rxdb";

import styles from "../SettingsCmp.module.scss";

export const SubSettingAppearance = (props: {
  settings: RxSettingsEntryNaked;
  themeMode: ThemeMode;
  onThemeModeChange: (mode: ThemeMode) => void;
  onUpdate: (upd: Partial<RxSettingsEntryNaked>) => void;
}) => {
  return (
    <Box className={styles.Settings__Section}>
      <Typography className={styles.Settings__Label}>
        Background color:
      </Typography>
      <Box className={styles.Settings__ThemeContainer}>
        <Box
          onClick={() => props.onThemeModeChange("light")}
          sx={{
            "&:hover": {
              borderColor: (theme) => theme.palette.primary.main,
            },
            borderColor: (theme) =>
              props.themeMode === "light"
                ? theme.palette.primary.main
                : "transparent",
          }}
          className={styles.Settings__Theme}
        >
          <LightTheme />
        </Box>
        <Box
          onClick={() => props.onThemeModeChange("dark")}
          sx={{
            "&:hover": {
              borderColor: (theme) => theme.palette.primary.main,
            },
            borderColor: (theme) =>
              props.themeMode === "dark"
                ? theme.palette.primary.main
                : "transparent",
          }}
          className={styles.Settings__Theme}
        >
          <DarkTheme />
        </Box>
        <Box
          onClick={() => props.onThemeModeChange("system")}
          sx={{
            "&:hover": {
              borderColor: (theme) => theme.palette.primary.main,
            },
            borderColor: (theme) =>
              props.themeMode === "system"
                ? theme.palette.primary.main
                : "transparent",
          }}
          className={styles.Settings__Theme}
        >
          <SystemTheme />
        </Box>
      </Box>

      <Typography className={styles.Settings__Label}>App color:</Typography>
      <ColorSelector
        variant="settings"
        value={props.settings.theme}
        onSelect={(color) => props.onUpdate({ theme: color })}
      />
    </Box>
  );
};
