export enum LS_KEY {
  TermsOfServiceAccepted = "TermsOfServiceAccepted",
}

export enum TaskType {
  Normal = 0,
  LegacyRecurringInstance = 1,
  Setup1 = 11,
  Setup2 = 12,
  Setup3 = 13,
  Setup4 = 14,
  Onboarding1 = 21,
  Onboarding2 = 22,
  onboarding3 = 23,
  RecurringMorning = 31,
  RecurringNight = 32,
}

export const DEFAULT_TASK_DURATION = 15;

export const DEFAULT_THEME_COLOR = "day";
