import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import { Box, IconButton } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { type TmpTask } from "@structured/utils/tasks";
import dayjs from "dayjs";
import { type Control, Controller, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface TimePickerRowProps {
    disabled: boolean;
    task: TmpTask;
    control: Control<FormValues>;
    setValue: UseFormSetValue<FormValues>;
    taskDuration: number;
    setTaskDuration: (duration: number) => void;
}

export const TimePickerRow = (props: TimePickerRowProps) => {
    const endTime = props.taskDuration === 1
        ? dayjs().startOf("day").add(props.task.start_time, "hour")
        : dayjs().startOf("day").add(props.task.start_time + props.taskDuration / 60, "hour");
    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <AccessAlarmOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box
                sx={{
                    display: "flex",
                    width: "31.3125rem",
                    alignItems: "center",
                    gap: "1rem",
                }}
            >
                <Controller
                    control={props.control}
                    name="task.start_time"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <TimePicker
                            slotProps={{
                                textField: { size: "small", fullWidth: true },
                                openPickerIcon: { fontSize: "inherit" },
                                openPickerButton: { size: "small" },
                            }}
                            value={dayjs().startOf("day").add(value, "hour")}
                            onChange={(ev) =>
                                onChange(
                                    dayjs
                                        .duration({
                                            hours: ev.hour(),
                                            minutes: ev.minute(),
                                        })
                                        .asHours()
                                )
                            }
                            {...field}
                        />
                    )}
                />
                <ArrowForwardIcon
                    color="action"
                    sx={{
                        width: "1.25rem",
                        height: "1.25rem",
                        flexShrink: 0,
                    }}
                />
                <Controller
                    control={props.control}
                    name="task.duration"
                    disabled={props.disabled}
                    render={({ field: { onChange, value, ...field } }) => (
                        <TimePicker
                            slotProps={{
                                textField: { size: "small", fullWidth: true },
                                openPickerIcon: { fontSize: "inherit" },
                                openPickerButton: { size: "small" },
                            }}
                            value={endTime}
                            onChange={(ev) => {
                                const startTime = props.task.start_time;
                                const newEndTime = dayjs.duration({
                                    hours: ev.hour(),
                                    minutes: ev.minute(),
                                }).asHours();

                                if (newEndTime < startTime) {
                                    props.setValue("task.start_time", newEndTime);
                                    props.setTaskDuration(1);
                                    onChange(1);
                                } else {
                                    const durationInMinutes = Math.round((newEndTime - startTime) * 60);
                                    props.setTaskDuration(durationInMinutes);
                                    onChange(durationInMinutes);
                                }
                            }}
                            {...field}
                        />
                    )}
                />
                <IconButton
                    sx={{
                        display: "flex",
                        width: "3.125rem",
                        height: "3.125rem",
                        padding: "1rem",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.625rem",
                        borderRadius: "0.5rem",
                        border: "0",
                    }}
                    disableRipple
                    disabled={props.disabled}
                >
                    <PublicOutlinedIcon
                        sx={{
                            color: "action",
                            flexShrink: 0,
                            width: "1.25rem",
                            height: "1.25rem",
                        }}
                    />
                </IconButton>
            </Box>
        </TaskFormRowContainer>
    );
};