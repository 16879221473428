import { Close, MoreHoriz } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { DottedDivider } from "@structured/components";

export const TaskDrawerHeader = ({ onClose, focusText }: { onClose: () => void, focusText: string }) => (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: "1.5rem 1.5rem 0" }}>
            <Typography variant="h5" fontWeight={700}>
                {focusText} <Typography component="span" color="primary" variant="inherit">Task</Typography>
            </Typography>
            <Box>
                <IconButton onClick={onClose}><Close /></IconButton>
            </Box>
        </Box>
        <DottedDivider sx={{ marginTop: "1rem" }} />
    </Box>
);