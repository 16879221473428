import { useSession, useSettings } from "@structured/store";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export interface ProtectedWithRouteProps {
  readonly redirectPath?: string;
  readonly children?: React.ReactNode;
}

export const ProtectedWithTermsRoute: React.FC<ProtectedWithRouteProps> = ({
  children,
  redirectPath = "/auth",
}) => {
  const session = useSession();
  const { settings } = useSettings();

  if (!session) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  if (!settings?.did_complete_onboarding) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return children ? <>{children}</> : <Outlet />;
};
