import { useTasks } from "@structured/store";

import { getIsoStrDateOnly } from "../date";

export const useDailyTasks = (day: Date) => {
  const { tasks } = useTasks();

  const date = getIsoStrDateOnly(day);

  return tasks
    .filter((task) => task.localDay === date && !task.is_hidden)
    .sort((a, b) => (a.localStartTime ?? 0) - (b.localStartTime ?? 0));
};
