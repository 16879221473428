import { useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import panelElements from "@structured/assets/signup-page-images/panelElements.png";
import signUpPageLogo from "@structured/assets/signup-page-images/signup-page-logo.svg";
import transitionImage from "@structured/assets/signup-page-images/transitionImage.png";
export const StaticSidePanelComponent = ({ gradient }: { gradient: string }) => {
    const bigScreen = useMediaQuery("(min-width: 1440px)");
    return (
        <Box sx={{ height: "100vh", background: gradient, position: "relative", }}>
            <Box component="img" src={transitionImage} sx={{ position: "absolute", width: "100%", height: "100%", userSelect: "none", zIndex: 1, }} />
            <Box component="img" src={signUpPageLogo} alt="Structured" sx={{ margin: "1.875rem 0 0 2.5rem", zIndex: 2 }} />
            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "1.875rem", }}>
                <Box component="img" src={panelElements} alt="Notebook and Coffee" sx={{ position: "relative", zIndex: 2, width: bigScreen ? "90%" : "100%", height: "auto" }} />
            </Box>
        </Box>
    )
}