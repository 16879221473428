import { getFirstFreePeriodStart } from "../tasks";
import { useDailyTimelineEntries } from "./useDailyTimelineEntries";

export const useFirstFreePeriod = (
  day: Date,
  currentDay: Date,
  minDuration: number
): Date => {
  const timelineEntries = useDailyTimelineEntries(day, currentDay);

  return getFirstFreePeriodStart(timelineEntries, minDuration, day, currentDay);
};
