import { type TimelineEntry,TimelineEntryType } from "@structured/utils/timeline";
import React from "react";

import { TimelineDashedLine } from "./TimelineDashedLine";
import { TimelineNormalLine } from "./TimelineNormalLine";

export interface TimelineLineProps {
  readonly entry: TimelineEntry;
  readonly expanded?: boolean;
}

export const TimelineLine: React.FC<TimelineLineProps> = ({
  entry,
  expanded,
}) => {
  switch (entry.type) {
    case TimelineEntryType.TASK: {
      return <TimelineNormalLine expanded={expanded} entry={entry} />;
    }

    default: {
      return <TimelineDashedLine entry={entry} />;
    }
  }
};
