import MuiIcon, { type IconProps } from "@mui/material/Icon";
import {
  DEFAULT_TASK_MATERIAL_ICON,
  DEFAULT_TASK_SYMBOL_ID,
} from "@structured/utils/icons";
import classNames from "classnames";
import React from "react";

export interface IcoProps extends Omit<IconProps, "fontSize"> {
  readonly name: string;
  readonly fontSize?: string;
}

export const Icon: React.FC<IcoProps> = ({
  name,
  className,
  fontSize = "1.5rem",
  onClick,
  ...props
}) => {
  return (
    <MuiIcon
      sx={{ fontSize, ...props.sx }}
      className={classNames([
        className,
        "f7-icons",
        { "cursor-pointer": onClick },
      ])}
      onClick={(e) => onClick?.(e)}
      {...props}
    >
      {name === DEFAULT_TASK_SYMBOL_ID ? DEFAULT_TASK_MATERIAL_ICON : name}
    </MuiIcon>
  );
};
