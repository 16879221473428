import type { RxTaskNaked } from "../rxdb";

export interface TmpSubtask {
  id: string;
  title: string;
  completed_at: null | string;
}

export enum RecurringType {
  Once = 0,
  Daily = 1,
  Weekly = 2,
  Monthly = 3,
}

export interface RecurringOwnFields {
  recurring_type: RecurringType;
  interval: number;
  start_day: string;
  end_day: string | null;
  monday: boolean | null;
  tuesday: boolean | null;
  wednesday: boolean | null;
  thursday: boolean | null;
  friday: boolean | null;
  saturday: boolean | null;
  sunday: boolean | null;
}

export interface TmpTask
  extends Omit<RxTaskNaked, "user_id" | "created_at" | "modified_at"> {}
