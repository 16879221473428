import { type Recurring } from "@structured/store";
import dayjs from "dayjs";

import { RecurringType } from "./task.types";

// NOTE: week starts at sunday in js
const WEEKDAY_MAP = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
] as (keyof Recurring)[];

export const isRecurringOnDay = (
  recurringCfg: Recurring,
  dayToCheckDate: Date
) => {
  const startDayDate = new Date(recurringCfg.localStartDay);
  if (
    startDayDate > dayToCheckDate ||
    (recurringCfg.localEndDay && new Date(recurringCfg.localEndDay) < dayToCheckDate)
  ) {
    return false;
  }

  const interval = recurringCfg.interval ?? 1;

  switch (recurringCfg.recurring_type) {
    case RecurringType.Daily: {
      if (interval === 1) {
        return true;
      }
      const diffInDays = dayjs(dayToCheckDate).diff(startDayDate, "day");
      return (
        // start date is not in the future
        diffInDays >= 0 && diffInDays % interval === 0
      );
    }
    case RecurringType.Weekly: {
      const weekday = dayToCheckDate.getDay();
      const dayProp = WEEKDAY_MAP[weekday];
      if (interval === 1) {
        return !!recurringCfg[dayProp];
      }

      const diffInWeeks = dayjs(dayToCheckDate).diff(startDayDate, "week");
      return (
        // start date is not in the future
        diffInWeeks >= 0 &&
        diffInWeeks % interval === 0 &&
        !!recurringCfg[dayProp]
      );
    }
    case RecurringType.Monthly: {
      const startDayDay = startDayDate.getDate();
      const daysInCurrentMonth = new Date(
        dayToCheckDate.getFullYear(),
        dayToCheckDate.getMonth() + 1,
        0
      ).getDate();

      const isCreationDayThisMonth =
        // checking for weird edge case when the month has fewer days total than the startDay
        startDayDay >= daysInCurrentMonth
          ? daysInCurrentMonth <= dayToCheckDate.getDate()
          : dayToCheckDate.getDate() === startDayDate.getDate();
      if (interval === 1) {
        return isCreationDayThisMonth;
      }

      const diffInMonth = dayjs(dayToCheckDate).diff(startDayDate, "month");
      return (
        isCreationDayThisMonth &&
        // start date is not in the future
        diffInMonth >= 0 &&
        diffInMonth % interval === 0
      );
    }
  }
  return false;
};
