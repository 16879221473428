import { type Recurring, type Task } from "@structured/store";
import dayjs from "dayjs";

export const addLocalTimeToTask = <T extends Task | Recurring>(task: T): T => {
  if ("recurring_type" in task) {
    if (!task.timezone) {
      return {
        ...task,
        localStartDay: task.start_day,
        localEndDay: task.end_day,
        localStartTime: task.start_time,
      };
    }

    let foreignStartDay: dayjs.Dayjs;
    let foreignEndDay: dayjs.Dayjs | undefined;

    if (task.timezone.includes("GMT")) {
      foreignStartDay = dayjs
        .utc(task.start_day)
        .startOf("day")
        .add(task.start_time, "hour")
        .utcOffset(task.timezone);

      foreignEndDay =
        task.end_day &&
        dayjs
          .utc(task.end_day)
          .startOf("day")
          .add(task.start_time, "hour")
          .utcOffset(task.timezone);
    } else {
      foreignStartDay = dayjs
        .tz(task.start_day, task.timezone)
        .startOf("day")
        .add(task.start_time, "hour");
      foreignEndDay =
        task.end_day &&
        dayjs
          .tz(task.end_day, task.timezone)
          .startOf("day")
          .add(task.start_time, "hour");
    }

    const localStartDay = foreignStartDay.tz(dayjs.tz.guess());
    const localEndDay = foreignEndDay?.tz(dayjs.tz.guess());

    return {
      ...task,
      localStartDay: localStartDay.format("YYYY-MM-DD"),
      localEndDay: localEndDay?.format("YYYY-MM-DD"),
      localStartTime: dayjs
        .duration({
          hours: localStartDay.hour(),
          minutes: localStartDay.minute(),
        })
        .asHours(),
    };
  } else {
    if (!task.timezone) {
      return {
        ...task,
        localDay: task.day,
        localStartTime: task.start_time,
      };
    }

    let foreignStartDay: dayjs.Dayjs;

    if (task.timezone.includes("GMT")) {
      foreignStartDay = dayjs
        .utc(task.day)
        .startOf("day")
        .add(task.start_time, "hour")
        .utcOffset(task.timezone);
    } else {
      foreignStartDay = dayjs
        .tz(task.day, task.timezone)
        .startOf("day")
        .add(task.start_time, "hour");
    }

    const localStartDay = foreignStartDay.tz(dayjs.tz.guess());

    return {
      ...task,
      localDay: localStartDay.format("YYYY-MM-DD"),
      localStartTime: dayjs
        .duration({
          hours: localStartDay.hour(),
          minutes: localStartDay.minute(),
        })
        .asHours(),
    };
  }
};
