import {
  type ExtractDocumentTypeFromTypedRxJsonSchema,
  type RxJsonSchema,
  toTypedRxJsonSchema,
} from "rxdb";

import { DATE_STR_LENGTH } from "../rxdb.const";

export const SETTINGS_SCHEMA_LITERAL = {
  title: "settings schema",
  version: 2, // Incremented version to reflect the update
  primaryKey: "user_id",
  type: "object",
  properties: {
    // STANDARD REPLICATION FIELDS
    schema_version: {
      type: "integer",
    },
    replication_revision: {
      type: "string",
      minLength: 8,
    },
    received_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    // OTHER STANDARD FIELDS
    user_id: {
      type: "string",
      minLength: 8,
      maxLength: 100,
    },
    created_at: {
      // datetime string
      type: "string",
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    modified_at: {
      // datetime string
      type: ["string", "null"],
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    _deleted: {
      type: "boolean",
    },

    // OTHER FIELDS
    analytics_id: {
      type: ["string", "null"],
    },
    cloud_terms_date: {
      type: ["string", "null"],
    },
    theme: {
      type: "string",
    },
    font: {
      type: "string",
    },
    layout: {
      type: "string",
    },
    did_complete_onboarding: {
      type: "boolean",
    },
    is_analytics_enabled: {
      type: "boolean",
    },
    first_weekday: {
      type: ["integer", "null"],
    },
    duration_presets: {
      type: ["array", "null"],
      items: {
        type: "integer",
      },
    },
    recent_symbols: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
        },
      },
    },
    recent_timezones: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
        },
      },
    },
    alert_sound: {
      type: "string",
    },
    all_day_default_alerts: {
      type: "array",
      items: {
        type: "number",
      },
    },
    displayed_surveys: {
      type: "array",
      items: {
        type: "string",
      },
    },
    initial_build: {
      type: ["integer", "null"],
    },
    installation_date: {
      type: ["string", "null"],
      minLength: DATE_STR_LENGTH,
      maxLength: DATE_STR_LENGTH + 8,
    },
    is_calendar_alert_enabled: {
      type: "boolean",
    },
    is_icon_analytics_enabled: {
      type: "boolean",
    },
    is_reminder_alert_enabled: {
      type: "boolean",
    },
    pro_provider: {
      type: ["string", "null"],
    },
    timed_default_alerts: {
      type: "array",
      items: {
        type: "string",
      },
    },
    wake_up_time: {
      type: "number",
    },
    ai_terms_date: {
      type: ["string", "null"],
    },
    donation_terms_date: {
      type: ["string", "null"],
    },
    allows_popups: {
      type: "boolean",
    },
    allows_surveys: {
      type: "boolean",
    },
    is_energy_monitor_enabled: {
      type: "boolean",
    },
    is_haptics_enabled: {
      type: "boolean",
    },
    is_sound_effect_enabled: {
      type: "boolean",
    },
    recent_sounds: {
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
          },
        },
      },
    },
    daily_energy_levels: {
      type: "array",
      items: {
        type: "object",
        properties: {
          // Define the properties of `daily_energy_levels` if needed
        },
      },
    },
  },

  required: [
    "user_id",
    "replication_revision",
    "created_at",
    "modified_at",
    "_deleted",
  ],
  indexes: [],
} as const;

const SCHEMA_TYPED = toTypedRxJsonSchema(SETTINGS_SCHEMA_LITERAL);
export type RxSettingsEntryNaked = ExtractDocumentTypeFromTypedRxJsonSchema<
  typeof SCHEMA_TYPED
>;
export const SETTINGS_SCHEMA: RxJsonSchema<RxSettingsEntryNaked> =
  SETTINGS_SCHEMA_LITERAL as any as RxJsonSchema<RxSettingsEntryNaked>;
