import Box from "@mui/material/Box";
import { useForwardRef } from "@structured/utils/hooks";
import { ICONS_BY_CATEGORY } from "@structured/utils/icons";
import React from "react";

import styles from "./IconPicker.module.scss";
import { IconPickerCategory } from "./IconPickerCategory";

export interface IconPickerProps {
  readonly onSelectIcon: (selectedIconId: string | null) => void;
  readonly onVisibleCategory: (categoryId: string) => void;
  readonly selectedIconId: string | null;
}

export const IconPicker = React.forwardRef<HTMLDivElement, IconPickerProps>(
  function IconPicker(props, ref) {
    const forwardedRef = useForwardRef(ref);

    return (
      <Box ref={forwardedRef} className={styles.IconPicker}>
        {ICONS_BY_CATEGORY.map((category) => (
          <IconPickerCategory
            key={category.id}
            category={category}
            rootRef={forwardedRef.current}
            selectedIcon={props.selectedIconId}
            onSelectIcon={props.onSelectIcon}
            onVisible={() => props.onVisibleCategory(category.id)}
          />
        ))}
      </Box>
    );
  }
);
