import { type Recurring, type Task } from "@structured/store";
import dayjs, { type Dayjs } from "dayjs";

export const formatForeignTime = (
  task: Task | Recurring,
  day: Date
): string => {
  if (task.is_all_day) {
    return null;
  }

  if (!task.timezone) {
    return null;
  }

  let startTime = dayjs
    .tz(day, dayjs.tz.guess())
    .startOf("day")
    .add(task.localStartTime, "hour");

  if (task.timezone.includes("GMT")) {
    startTime = startTime.utcOffset(task.timezone);
  } else {
    startTime = startTime.tz(task.timezone);
  }

  let endTime: Dayjs;

  if (task.duration > 1 && task.localStartTime) {
    endTime = startTime.add(task.duration, "minutes");
  }

  return `${startTime.format("DD/MM/YY, hh:mm A")} ${
    endTime ? `- ${endTime.format("hh:mm A")}` : ""
  } ${startTime.format("Z")}`;
};
