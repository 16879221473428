import { Box, Typography } from "@mui/material";

export const BETABadgeComponent = ({ inverse }: { inverse?: boolean }) => (
    <Box
        sx={{
            p: "0.625rem 1.25rem",
            borderRadius: "0.75rem",
            mt: "1.875rem",
            mr: "2.5rem",
            bgcolor: (theme) => !inverse ? theme.palette.primary.main : "white",
        }}
    >
        <Typography
            fontSize="1.25rem"
            fontWeight={700}
            color={(theme) => inverse ? theme.palette.primary.main : "white"}
        >
            BETA
        </Typography>
    </Box>
);