import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TimelineContent from "@mui/lab/TimelineContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useUI } from "@structured/store/ui/ui.provider";
import { type TimelineAddTaskPromptEntry } from "@structured/utils/timeline";
import dayjs from "dayjs";

import styles from "./TimelineAddTaskPrompt.module.scss";

export const TimelineAddTaskPrompt = (props: {
  entry: TimelineAddTaskPromptEntry;
}) => {
  const { setIsShowAddTaskModal } = useUI();

  return (
    <TimelineContent>
      <div className={styles.TimelineAddTask}>
        <div className={styles.TimelineAddTask__Content}>
          <Typography
            variant="body2"
            color="text.secondary"
            className={styles.TimelineAddTask__Message}
          >
            <ScheduleIcon fontSize="small" />
            &nbsp;Major strides in&nbsp;
            <Typography
              display="contents"
              variant="body2"
              color="primary"
              fontWeight={600}
              component="em"
            >
              {dayjs
                .duration(props.entry.duration, "minute")
                .format("H[h] mm[m]")}
            </Typography>
            ?
          </Typography>
        </div>
        <div>
          {/* TODO: move to separate styled component */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => setIsShowAddTaskModal(true)}
          >
            <AddCircleOutlineIcon />
            &nbsp;Add Task
          </Button>
        </div>
      </div>
    </TimelineContent>
  );
};
