import { useSession } from "@structured/store";
import { createTelemetryDeck, TelemetryDeckProvider } from "@typedigital/telemetrydeck-react";
import { type ReactNode, useEffect, useState } from "react";

type TelemetryDeckType = ReturnType<typeof createTelemetryDeck>;

export const TelemetryProvider = ({ children }: { children: ReactNode }) => {
    const session = useSession();
    const [telemetryDeck, setTelemetryDeck] = useState<TelemetryDeckType | null>(null);

    useEffect(() => {
        const user = session?.user.email || "anon";
        const deck = createTelemetryDeck({
            appID: process.env.REACT_APP_TELEMETRY_APP_ID,
            clientUser: user,
            testMode: false
        });
        setTelemetryDeck(deck);
    }, [session]);

    const trackPageView = async () => {
        if (!telemetryDeck) return;
        await telemetryDeck.signal("pageview", { component: "Structured Web", path: window.location.pathname });
    };

    useEffect(() => {
        if (telemetryDeck) void trackPageView();
    }, [telemetryDeck]);

    return (
        <TelemetryDeckProvider telemetryDeck={telemetryDeck}>
            {children}
        </TelemetryDeckProvider>
    );
};

export default TelemetryProvider;
