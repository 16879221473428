import { useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import DataProtection from "@structured/assets/data-protection.png";
import { ModalWrapper } from "@structured/components";
import { useRecurringTasks, useSession, useSettings } from "@structured/store";
import { LS_KEY } from "@structured/utils/common";
import { DayView } from "@structured/views/DayView/DayView";
import localforage from "localforage";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import styles from "./TermsOfService.module.scss";
export const TermsOfService = () => {
  const session = useSession();
  const { settings, updateSettings } = useSettings();
  const navigate = useNavigate();

  const handleAccept = async () => {
    await localforage.setItem(LS_KEY.TermsOfServiceAccepted, true);

    await updateSettings(session.user.id, {
      cloud_terms_date: new Date().toISOString(),
    });
    if (!settings.did_complete_onboarding) {
      await createOnboardingTasks(session.user.id);
    } else {
      console.log("Onboarding tasks already created");
    }
    navigate("/", { replace: true });

  };

  const theme = useTheme();
  const { createOnboardingTasks } = useRecurringTasks();
  useEffect(() => {
    if (settings?.cloud_terms_date) {
      navigate("/", { replace: true });
    }
  }, [settings, navigate]);
  return (
    settings && (
      <>
        <DayView />
        <ModalWrapper open={true}>
          <Box className={styles.TermsOfService__Container}>
            <Box component="img" src={DataProtection} sx={{
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              width: "18.25rem",
              height: "9.5625rem",
            }}
            />
            <Box className={styles.TermsOfService__Body}>
              <Typography
                sx={{
                  background: `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
                fontSize="2rem"
                fontWeight={700}
                lineHeight="2.5rem"
                textAlign="center"
                marginTop="1.625rem"

              >
                Your Privacy & Data
              </Typography>
              <Typography
                fontSize="0.875rem"
                fontWeight={400}
                lineHeight="1.375rem"
                textAlign="left"
                color={"text.secondary"}
              >
                When using Structured Cloud, your tasks and settings are sent and
                stored on our server. They are stored for the sole purpose of
                syncing them across all your devices.
              </Typography>
              <Typography
                fontSize="0.875rem"
                fontWeight={400}
                lineHeight="1.375rem"
                textAlign="left"
                color={"text.secondary"}
              >
                To continue, you’ll need to accept our{" "}
                <Link href="https://strct.app/tos" target="_blank">
                  Terms of Service
                </Link>{" "}
                and{" "}
                <Link href="https://strct.app/dpa" target="_blank">
                  Data Processing Agreement.
                </Link>
              </Typography>
              <Typography
                fontSize="0.875rem"
                fontWeight={400}
                lineHeight="1.375rem"
                textAlign="left"
                color={"text.secondary"}
              >
                Further information about the processing of your personal data can
                be found in our{" "}
                <Link href="https://strct.app/privacy_policy" target="_blank">
                  Privacy Policy
                </Link>
                .
              </Typography>

            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.75rem",
                alignSelf: "stretch",
                marginTop: "2.5rem",
              }}
            >
              <Button
                onClick={handleAccept}
                color="primary"
                variant="contained"
                size="large"
                fullWidth
              >
                Accept Terms
              </Button>
            </Box>
          </Box>
        </ModalWrapper>
      </>
    )
  );
};
