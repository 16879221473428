import { IconButton } from "@mui/material";
import React from "react";
export const TaskRowIcon = (props: { disabled: boolean, children: React.ReactNode }) => {
    return (
        <IconButton
            sx={{
                display: "flex",
                width: "3.125rem",
                height: "3.125rem",
                padding: "1rem",
                justifyContent: "center",
                alignItems: "center",
                gap: "0.625rem",
                borderRadius: "0.5rem",
                border: "0",
                cursor: "default"
            }}
            disableRipple
            disabled={props.disabled}
        >
            {props.children}
        </IconButton>
    );
}