import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React from "react";

import { ModalWrapper } from "./Modal";

export interface ConfirmationModalProps {
  readonly open: boolean;
  readonly description: string;
  readonly onConfirm: () => void;
  readonly onCancel: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  return (
    <ModalWrapper
      open={props.open}
      header={false}
      onClose={() => props.onCancel?.()}
      actions={
        <>
          <Button onClick={() => props.onCancel?.()}>Cancel</Button>
          <Button
            onClick={() => props.onConfirm?.()}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        </>
      }
    >
      <Typography>{props.description}</Typography>
    </ModalWrapper>
  );
};
