import { deleteAllLocalData } from "./deleteAllLocalData";

export const resyncBackend = async () => {
  await deleteAllLocalData();

  /*
  NOTE: we resort to reloading since it is the easiest and cleanest way to reset the whole app and
  avoiding memory leaks etc.
   */
  Array.from(document.body.children).forEach((el) => el.remove());
  window.location.reload();
};
