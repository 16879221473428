import { checkValidReplicationDoc } from "./checkValidReplicationDoc";

export const removeNonSchemaFields = <DT>(
  doc: DT | {},
  schemaProperties: { [key: string]: { [key: string]: any } }
): DT => {
  const copy = { ...doc };

  // Delete excess fields to make it work with remote schema upgrades
  // This happens when server model is never than local model.
  Object.keys(copy as {}).forEach((key) => {
    if (!schemaProperties[key]) {
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete copy[key];
    }
  });
  return copy as DT;
};

export const removeNonSchemaFieldsAndCheckValidReplicationDoc = <DT>(
  doc: DT | {},
  schemaProperties: { [key: string]: { [key: string]: any } }
): DT => {
  return removeNonSchemaFields(checkValidReplicationDoc(doc), schemaProperties);
};
