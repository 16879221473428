import { type Task, useOccurrences, useTasks, useUI } from "@structured/store";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";

import { getIsoStrDateOnly } from "../date";
import {
  type RxRecurringNaked,
  type RxSubtask,
  type RxTaskNaked,
  TMP_REV,
} from "../rxdb";
import {
  formatForeignTime,
  formatTaskTimeWindow,
  isRecurringTask,
} from "../tasks";
import { useToggleCompletedAt } from "./useToggleCompletedAt";

export interface UseTaskResponse {
  readonly task: Task | RxRecurringNaked;
  readonly isRecurring: boolean;
  readonly formattedTime: string;
  readonly formattedForeignTime: string;
  readonly subtasks: RxSubtask[];
  readonly completedSubtasks: RxSubtask[];
  readonly completedAt: string | null;
  readonly updateSubtask: (
    subtaskId: string,
    changes: Partial<RxSubtask>
  ) => void;
  readonly toggleCompletedAt: () => void;
  readonly openEditModal: (props?: Partial<Task | RxRecurringNaked>) => void;
}

export const useTask = (
  task: Task | RxRecurringNaked,
  taskDate: Date
): UseTaskResponse => {
  const { updateTask } = useTasks();
  const { setContextModalTask } = useUI();
  const {
    addOccurrence,
    updateOccurrence,
    getDailyOccurrenceForRecurringTask,
  } = useOccurrences();

  const isRecurring = isRecurringTask(task);

  const taskOccurrence = getDailyOccurrenceForRecurringTask(task.id, taskDate);

  const completedAt =
    taskOccurrence?.completed_at ?? (task as RxTaskNaked).completed_at ?? null;

  const formattedTime = formatTaskTimeWindow(task, taskDate);
  const formattedForeignTime = formatForeignTime(task, taskDate);

  const { toggleCompletedAt } = useToggleCompletedAt(
    taskDate,
    task,
    taskOccurrence
  );

  const subtasks: RxSubtask[] = task.subtasks.map((subtask) => {
    const subtaskOccurrence = taskOccurrence?.subtask_occurrences?.find(
      (occurrence) => occurrence.subtask === subtask.id
    );

    return {
      id: subtask?.id,
      title: subtask?.title,
      completed_at: subtaskOccurrence?.completed_at ?? subtask.completed_at,
    };
  });

  const completedSubtasks = subtasks.filter(
    (subtask) => !!subtask.completed_at
  );

  const updateSubtask = (subtaskId: string, changes: Partial<RxSubtask>) => {
    const updatedSubtasks = subtasks.map((subtask) => {
      return subtask.id === subtaskId ? { ...subtask, ...changes } : subtask;
    });

    if (isRecurring) {
      const subtaskOccurrences = updatedSubtasks.map((subtask) => ({
        id: uuid(),
        subtask: subtask.id,
        completed_at: subtask.completed_at,
      }));

      if (taskOccurrence) {
        void updateOccurrence(taskOccurrence.id, {
          subtask_occurrences: subtaskOccurrences,
        });
      } else {
        void addOccurrence({
          id: uuid(),
          user_id: task.user_id,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          recurring: task.id,
          day: getIsoStrDateOnly(taskDate),
          replication_revision: TMP_REV,
          order_index: null,
          completed_at: null,
          detached_task: null,
          is_detached: false,
          _deleted: false,
          subtask_occurrences: subtaskOccurrences,
        });
      }
    } else {
      void updateTask(task.id, { subtasks: updatedSubtasks });
    }
  };

  const openEditModal = (props?: Partial<Task | RxRecurringNaked>) => {
    setContextModalTask({
      taskOrRecurring: { ...task, ...props, completed_at: completedAt },
      dayTimestamp: taskDate.getTime(),
    });
  };

  return {
    isRecurring,
    task,
    formattedTime,
    formattedForeignTime,
    subtasks,
    completedSubtasks,
    completedAt,
    updateSubtask,
    toggleCompletedAt,
    openEditModal,
  };
};
