import { type Task, useOccurrences, useTasks } from "@structured/store";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import dayjs from "dayjs";

import {
  type RxRecurringNaked,
  type RxRecurringOccurrenceNaked,
  type RxTaskNaked,
  TMP_REV,
} from "../rxdb";
import { isRecurringTask } from "../tasks";

// TODO: replace toggle and make it accept a `completed` boolean value
export const useToggleCompletedAt = (
  taskDay: Date,
  taskOrRecurring: Task | RxRecurringNaked,
  occurrence?: RxRecurringOccurrenceNaked
) => {
  const { updateTask } = useTasks();
  const { updateOccurrence, addOccurrence } = useOccurrences();

  const toggleCompletedAt = async () => {
    const isRecurring = occurrence ?? isRecurringTask(taskOrRecurring);

    if (isRecurring) {
      if (occurrence?.id) {
        await updateOccurrence(occurrence.id, {
          completed_at: !occurrence.completed_at
            ? new Date().toISOString()
            : null,
        });
      } else {
        if (!taskDay) {
          throw new Error(
            "initialStartDayIfTask is required for recurring occurrence"
          );
        }

        await addOccurrence({
          completed_at: new Date().toISOString(),
          recurring: taskOrRecurring.id,
          user_id: taskOrRecurring.user_id,
          order_index: null,
          id: uuid(),
          detached_task: null,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          day: dayjs(taskDay).format("YYYY-MM-DD"),
          is_detached: false,
          replication_revision: TMP_REV,
          _deleted: false,
          subtask_occurrences: [],
        });
      }
    } else {
      await updateTask(taskOrRecurring.id, {
        completed_at: !(taskOrRecurring as RxTaskNaked).completed_at
          ? new Date().toISOString()
          : null,
      });
    }
  };

  return {
    toggleCompletedAt,
  };
};
