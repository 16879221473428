import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Icon } from "@structured/components/Icon";
import { useUI } from "@structured/store/ui/ui.provider";
import { getThemeColor } from "@structured/utils/colors";
import { getMaterialIdFromSymbolId } from "@structured/utils/icons";
import { type TimelineTaskEntry } from "@structured/utils/timeline";
import React, { useMemo } from "react";

import styles from "./TimelineLine.module.scss";

export interface TimelineNormalLineProps {
  readonly entry: TimelineTaskEntry;
  readonly expanded?: boolean;
}

export const TimelineNormalLine: React.FC<TimelineNormalLineProps> = ({
  entry,
  expanded,
}) => {
  const { setContextModalTask } = useUI();
  const taskOrRecurring = entry.taskOrRecurring;
  const taskDay: Date = entry.start;

  const taskHeight = useMemo(() => {
    const duration = entry.taskOrRecurring.duration ?? 0;
    const BASE = 64;
    const minDuration = 15;
    const maxDuration = 120;
    const maxMultiple = 4;
    const a: number = (BASE * (maxMultiple - 1)) / (maxDuration - minDuration);
    const b: number =
      BASE -
      ((BASE * (maxMultiple - 1)) / (maxDuration - minDuration)) * minDuration;
    const result: number = a * duration + b;

    return Math.min(Math.max(result, BASE), BASE * maxMultiple);
  }, [entry.taskOrRecurring.duration]);

  return (
    <TimelineSeparator
      sx={{
        ".MuiTimelineItem-root:hover &": {
          background: (theme) =>
            getThemeColor(taskOrRecurring.color, theme, "light"),
        },
      }}
    >
      <TimelineConnector
        sx={{
          backgroundColor: (theme) =>
            entry.progress > 0
              ? getThemeColor(taskOrRecurring.color, theme)
              : theme.palette.divider,
          maxHeight: expanded ? "3.3rem" : "100%",
          transition: (theme) =>
            !expanded
              ? theme.transitions.create("max-height", {
                  delay: theme.transitions.duration.shortest,
                })
              : "none",
        }}
      />
      <TimelineDot
        sx={{
          cursor: "pointer",
          height: taskHeight,
          background: (theme) =>
            `linear-gradient(${getThemeColor(taskOrRecurring.color, theme)} ${
              entry.progress
            }%, ${theme.palette.divider} ${
              entry.progress && entry.progress + 5
            }%)`,
          transition: (theme) =>
            theme.transitions.create("background", {
              delay: theme.transitions.duration.shortest,
            }),
        }}
        onClick={() =>
          setContextModalTask({
            taskOrRecurring,
            dayTimestamp: taskDay.getTime(),
          })
        }
      >
        <Icon
          fontSize="2rem"
          className={styles.TimelineLine__Icon}
          name={getMaterialIdFromSymbolId(taskOrRecurring.symbol)}
          sx={{
            color: (theme) =>
              entry.progress >= 40
                ? getThemeColor(entry.color, theme, "contrastText")
                : getThemeColor(entry.color, theme),
          }}
        />
      </TimelineDot>
      <TimelineConnector
        sx={{
          backgroundColor: (theme) =>
            entry.progress === 100
              ? getThemeColor(taskOrRecurring.color, theme)
              : theme.palette.divider,
        }}
      />
    </TimelineSeparator>
  );
};
