import { styled } from "@mui/material/styles";

export interface TimePickerMeridiemProps {
  readonly selected?: boolean;
  readonly color: "primary" | "night";
}

export const TimePickerMeridiem = styled("span")<TimePickerMeridiemProps>(
  ({ theme, ...props }) => ({
    backgroundColor: props.selected
      ? theme.palette[props.color].light
      : "transparent",
    color: props.selected
      ? theme.palette[props.color].main
      : theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    fontWeight: 500,
    textTransform: "capitalize",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    cursor: props.selected ? "default" : "pointer",

    "&:hover": {
      backgroundColor: theme.palette[props.color].light,
      color: theme.palette[props.color].main,
    },

    "&:first-of-type": {
      borderBottom: "1px solid",
    },
  })
);

export const TimePickerMeridiemContainer = styled("div")(({ theme }) => ({
  width: "3.25rem",
  borderRadius: "0.25rem",
  border: `1px solid ${theme.palette.divider}`,
  display: "flex",
  flexDirection: "column",
}));
