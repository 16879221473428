import {
  type RxConflictHandler,
  type RxConflictHandlerInput,
  type WithDeleted,
} from "rxdb";
import { type RxConflictHandlerOutput } from "rxdb/dist/types/types/conflict-handling";

import { type StandardReplicationModel } from "../rxdb.types";

export function createConflictHandler<DI extends StandardReplicationModel>(): RxConflictHandler<DI> {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return <RxConflictHandler<DI>>(
    async function conflictHandler(
      i: RxConflictHandlerInput<DI>
    ): Promise<RxConflictHandlerOutput<WithDeleted<DI>>> {
      if (
        i.newDocumentState.replication_revision ===
          i.realMasterState.replication_revision &&
        i.newDocumentState.received_at === i.realMasterState.received_at
      ) {
        /**
         * If the documents are equal (== have the same replication_revision)
         * -> we have no conflict.
         */
        return await Promise.resolve({
          isEqual: true,
        });
      }

      // the doc on the server is the one we send (apart from received_at that got updated there)
      // this means our local change is newer but was made before the server received_at update was received.
      if (
        i.assumedMasterState?.replication_revision ===
        i.realMasterState.replication_revision
      ) {
        console.log("SUPER QUICK EDIT CASE LOCAL DOC WINS", i);
        return await Promise.resolve({
          isEqual: false,
          documentData: {
            ...i.newDocumentState,
            received_at: i.realMasterState.received_at,
          },
        });
      }

      /**
       * Like the RxDB default conflict handler, we will always
       * drop the fork state and use the master state instead.
       */
      console.log("conflictHandler: USE MASTER STATE:", i);
      return await Promise.resolve({
        isEqual: false,
        documentData: i.realMasterState,
      });
    }
  );
}

// NOTE just here for typing
// const bla: RxConflictHandler<RxTaskNaked> = conflictHandler;
