import { useSession, useUI } from "@structured/store";
import { startReplicationForAll, useDb } from "@structured/utils/rxdb";
import { useEffect } from "react";
import { combineLatest, map, type Subscription } from "rxjs";

export const useReplicationSyncChecker = () => {
  const { db } = useDb();
  const session = useSession();
  const { setIsSyncing } = useUI();

  useEffect(() => {
    let replicationState: Subscription;
    if (session) {
      startReplicationForAll(db, session.user.id)
        .then((replicationStates) => {
          if (!replicationStates) {
            throw new Error("No replication states created");
          }

          replicationState = combineLatest(
            replicationStates.map((rs) => rs.active$)
          )
            .pipe(map((isActiveStates) => isActiveStates.includes(true)))
            .subscribe((isSyncing) => {
              setIsSyncing(isSyncing);
            });
        })
        .catch(console.error);
    } else{
      console.log("not here")
    }

    return () => {
      replicationState?.unsubscribe();
    };
  }, []);
};
