import { type Recurring, type Task } from "@structured/store";
import dayjs, { type Dayjs } from "dayjs";
import { type Duration } from "dayjs/plugin/duration";

export const formatTaskTimeWindow = (
  task: Task | Recurring,
  day: Date
): string => {
  if (task.is_all_day) {
    return `${day.toLocaleDateString()} (All-Day)`;
  }

  if ((task as Task).is_in_inbox) {
    return `${dayjs.duration(task.duration, "minutes").asMinutes()} min`;
  }

  let startTime = dayjs(day).startOf("day");
  let endTime: Dayjs;
  let duration: Duration;

  if (task.localStartTime !== undefined) {
    startTime = startTime.add(task.localStartTime, "hour");
  }

  if (task.duration > 0 && task.localStartTime !== undefined) {
    endTime = startTime.add(task.duration, "minutes");
    duration = dayjs.duration(task.duration, "minutes");
  }

  let timeWindow = startTime.format("h:mm A");

  if (endTime) {
    timeWindow += ` - ${endTime.format("h:mm A")}`;
  }

  if (duration) {
    timeWindow += ` (${duration.asMinutes()} min)`;
  }

  return timeWindow;
};
