import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { getThemeColor } from "@structured/utils/colors";
import {
  type TimelineAddTaskPromptEntry,
  type TimelineGapMsgEntry,
} from "@structured/utils/timeline";
import React from "react";

export interface TimelineDashedLineProps {
  readonly entry: TimelineGapMsgEntry | TimelineAddTaskPromptEntry;
}

export const TimelineDashedLine: React.FC<TimelineDashedLineProps> = ({
  entry,
}) => {
  return (
    <TimelineSeparator>
      <TimelineConnector
        className="dashed"
        sx={{
          background: (theme) =>
            entry.progress > 0
              ? `linear-gradient(${getThemeColor(entry.color, theme)} ${
                  entry.progress
                }%, ${theme.palette.divider})`
              : theme.palette.divider,

          "&::after": {
            borderRightColor: (theme) => theme.palette.background.default,
          },
        }}
      />
    </TimelineSeparator>
  );
};
