import { ICON_LIST } from "@structured/utils/icons";
import Fuse from "fuse.js";

const OPTS = {
  isCaseSensitive: false,
  includeScore: false,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: false,
  minMatchCharLength: 1,
  location: 0,
  threshold: 0.6,
  distance: 100,
  useExtendedSearch: false,
  ignoreLocation: false,
  ignoreFieldNorm: false,
  fieldNormWeight: 1,
  keys: ["name"],
};

export const getSymbolIdFromTitle = (title: string): string | undefined => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const fuse = new Fuse(ICON_LIST, OPTS);
  const r = fuse.search(title);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
  return (r[0]?.item as any)?.id || ICON_LIST[0].id;
};
