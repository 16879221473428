import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import IconButton from "@mui/material/IconButton";
import { type UseDateFieldProps } from "@mui/x-date-pickers/DateField";
import {
  DatePicker,
  type DatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import {
  type BaseSingleInputFieldProps,
  type DateValidationError,
  type FieldSection,
} from "@mui/x-date-pickers/models";
import type { Dayjs } from "dayjs";
import React from "react";

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<
      Dayjs | null,
      Dayjs,
      FieldSection,
      DateValidationError
    > {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ButtonField = (props: ButtonFieldProps) => {
  const {
    setOpen,
    id,
    disabled,
    InputProps: { ref } = {},
    inputProps: { "aria-label": ariaLabel } = {},
  } = props;

  return (
    <IconButton
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
    >
      <CalendarMonthOutlinedIcon />
    </IconButton>
  );
};

export const ButtonDatePicker = (
  props: Omit<DatePickerProps<Dayjs>, "open" | "onOpen" | "onClose">
) => {
  const [open, setOpen] = React.useState(false);

  return (
    <DatePicker
      slots={{ field: ButtonField, ...props.slots }}
      slotProps={{ field: { setOpen } as any }}
      {...props}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    />
  );
};
