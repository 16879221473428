import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Icon } from "@structured/components/Icon";
import { type Task, useOccurrences, useUI } from "@structured/store";
import { getThemeColor } from "@structured/utils/colors";
import {
  DEFAULT_TASK_MATERIAL_ICON,
  getMaterialIdFromSymbolId,
} from "@structured/utils/icons";
import {
  type RxRecurringNaked,
  type RxRecurringOccurrenceNaked,
  type RxTaskNaked,
} from "@structured/utils/rxdb";
import classNames from "classnames";

import styles from "./AllDayTask.module.scss";

export const AllDayTask = (props: {
  taskOrRecurring: Task | RxRecurringNaked;
  day: Date;
}) => {
  const { getDailyOccurrenceForRecurringTask } = useOccurrences();
  const { setContextModalTask } = useUI();
  const task = props.taskOrRecurring;

  const dailyOccurrences: RxRecurringOccurrenceNaked | null =
    getDailyOccurrenceForRecurringTask(task.id, props.day);

  const completedAt = dailyOccurrences
    ? dailyOccurrences.completed_at
    : (task as RxTaskNaked).completed_at ?? null;

  return (
    <Box
      onClick={() =>
        setContextModalTask({
          taskOrRecurring: task,
          dayTimestamp: props.day.getTime(),
        })
      }
      className={classNames(styles.AllDayTask, {
        [styles.isCompleted]: completedAt,
      })}
    >
      <Box
        className={styles.AllDayTask__Icon}
        sx={{
          backgroundColor: (theme) =>
            completedAt
              ? getThemeColor(task.color, theme)
              : theme.palette.divider,
        }}
      >
        <Icon
          name={
            getMaterialIdFromSymbolId(task.symbol) ?? DEFAULT_TASK_MATERIAL_ICON
          }
          sx={{
            color: (theme) =>
              completedAt
                ? getThemeColor(task.color, theme, "contrastText")
                : getThemeColor(task.color, theme),
          }}
        />
      </Box>
      <Typography
        textAlign="center"
        noWrap
        overflow="hidden"
        textOverflow="ellipsis"
        variant="body2"
        sx={{
          width: "100%",
          fontWeight: 700,
          color: completedAt ? "text.secondary" : "text.primary",
          opacity: completedAt ? 0.6 : 1,
          textDecoration: completedAt ? "line-through" : "none",
        }}
      >
        {task.title}
      </Typography>
    </Box>
  );
};
