import type { IconListEntry, IconMap, IconsByCategoryEntry } from "./icons.types";
import iconCategories from "./json/iconCategories.json";
import * as icons from "./json/icons.json";
import iconsByCategory from "./json/iconsByCategory.json";

export const DEFAULT_TASK_MATERIAL_ICON = "playlist_add_check";
export const DEFAULT_TASK_SYMBOL_ID = "___NOT_YET_GIVEN___";

export const ICON_LIST: IconListEntry[] = Object.keys(icons).map(
  (id) =>
    ({
      ...icons[id],
      id,
    } as IconListEntry)
);

export const ICONS_MAP = icons as IconMap;
export const ICONS_BY_CATEGORY = iconsByCategory as IconsByCategoryEntry[];
export const ICON_CATEGORIES = iconCategories as IconsByCategoryEntry[];
