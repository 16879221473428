import { useSession } from "@structured/store";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export interface UnprotectedRouteProps {
  readonly redirectPath?: string;
  readonly children?: React.ReactNode;
}

export const UnprotectedRoute: React.FC<UnprotectedRouteProps> = ({
  children,
  redirectPath = "/",
}) => {
  const session = useSession();

  if (session) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return children ? <>{children}</> : <Outlet />;
};
