import { Box, Card, CardContent, IconButton, Menu, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { getColor } from "@structured/utils/colors";
import React, { useState } from "react";
import { useDebouncyFn } from "use-debouncy";

import { ColorSelectorPaper } from "./ColorSelectorPaper";

export interface ColorSelectorProps {
  value?: string;
  onSelect: (color: string) => void;
  variant?: "paper" | "button" | "settings";
  disableDoubleView?: boolean;
  onClose?: () => void;
}

export const ColorSelector: React.FC<ColorSelectorProps> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const hexValue = getColor(props.value, theme.palette.mode);

  const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleColorChange = useDebouncyFn(
    (value: string) => props.onSelect(value),
    50
  );

  const handleMenuClose = () => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  switch (props.variant) {
    case "settings":
      return (
        <ColorSelectorPaper
          value={props.value}
          hexValue={hexValue}
          onChange={handleColorChange}
        />
      );

    case "paper":
      return (
        <Card>
          <CardContent
            sx={{
              borderRadius: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.5rem" }}>
              <Typography
                fontSize="0.75rem"
                fontWeight="600"
                color="text.primary"
              >
                Pick a color for your task
              </Typography>
            </Box>
            <ColorSelectorPaper
              value={props.value}
              hexValue={hexValue}
              onChange={handleColorChange}
            />
          </CardContent>
        </Card>
      );

    default: // "button" variant
      return (
        <>
          <IconButton
            disableRipple
            sx={{ background: hexValue }}
            onClick={handleAnchorClick}
          ></IconButton>
          <Menu
            slotProps={{
              paper: {
                sx: {
                  borderRadius: "0.5rem",
                  border: (theme) => `1px solid ${theme.palette.divider}`,
                },
              },
            }}
            MenuListProps={{ sx: { padding: "0.3rem" } }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            <ColorSelectorPaper
              value={props.value}
              hexValue={hexValue}
              onChange={handleColorChange}
            />
          </Menu>
        </>
      );
  }
};