import type { TaskColor } from "./colors.types";

export const LIGHT_COLOR_MAP: Record<TaskColor, string> = {
  day: "#F49F99",
  night: "#6185A8",
  nature: "#69A759",
  classic: "#141414",
} as const;

export const DARK_COLOR_MAP: Record<TaskColor, string> = {
  day: "#F88E87",
  night: "#5E96CB",
  nature: "#54A343",
  classic: "#FFFFFF",
} as const;

