import { type Recurring } from "@structured/store";

import { type RxRecurringOccurrenceNaked } from "../rxdb/schemas/recurringOccurrence.schema";
import { hasDetachedTasksOnDayForRecurring } from "./hasDetachedTasksOnDayForRecurring";
import { isRecurringOnDay } from "./isRecurringOnDay";

export const getDailyRecurringTasks = (
  recurringTasks: Recurring[],
  recurringOccurrences: RxRecurringOccurrenceNaked[],
  day: string
): Recurring[] => {
  const dayDate = new Date(day);
  return recurringTasks.filter(
    (task) =>
      isRecurringOnDay(task, dayDate) &&
      !hasDetachedTasksOnDayForRecurring(recurringOccurrences, dayDate, task.id)
  );
};
