import { FullPageLoadingSpinner } from "@structured/components";
import {
  useOccurrences,
  useRecurringTasks,
  useSettings,
  useTasks,
} from "@structured/store";
import { useReplicationSyncChecker } from "@structured/utils/hooks";
import React from "react";

import { DayView } from "./DayView/DayView";

export const Main: React.FC = () => {
  const { isValid: areTasksValid, isLoading: areTasksLoading } = useTasks();
  const {
    isValid: areRecurringTasksValid,
    isLoading: areRecurringTasksLoading,
  } = useRecurringTasks();
  const { isValid: areOccurrencesValid, isLoading: areOccurrencesLoading } =
    useOccurrences();
  const { isValid: areSettingsValid, isLoading: areSettingsLoading, settings: settingsProvider } =
    useSettings();

  if (
    areTasksLoading ||
    areRecurringTasksLoading ||
    areOccurrencesLoading ||
    areSettingsLoading
  ) {
    return <FullPageLoadingSpinner />;
  }

  if (
    !areTasksValid ||
    !areRecurringTasksValid ||
    !areOccurrencesValid ||
    !areSettingsValid
  ) {
    alert("Your application is not up to date, please refresh the browser");
    return;
  }

  useReplicationSyncChecker();

  return (
    <>
      <DayView />
    </>
  )
};