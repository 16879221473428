/* Displays tasks for a given day chronologically */
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Fab from "@mui/material/Fab";
import { Content } from "@structured/components/Content";
import { Drawer } from "@structured/components/Drawer";
import {
  useUI,
} from "@structured/store";
import React from "react";

import { AllDayTasks } from "../AllDayTasks/AllDayTasks";
import { GlobalModals } from "../GlobalModals/GlobalModals";
import { InboxHeader } from "../InboxTasks/InboxHeader";
import { InboxTasks } from "../InboxTasks/InboxTasks";
import { SelectDayBar } from "./SelectDayBar/SelectDayBar";
import { Timeline } from "./Timeline/Timeline";

export const DayView: React.FC = () => {
  const { selectedDay, setSelectedDay, toggleIsShowAddTaskModal } = useUI();

  return (
    <Box>
      <GlobalModals />
      <Drawer header={<InboxHeader />}>
        <InboxTasks />
      </Drawer>
      <Content>
        <SelectDayBar
          selectedDay={selectedDay}
          onDaySelect={(date) => setSelectedDay(date.getTime())}
        />
        <Divider />
      </Content>

      <Content paddingX="3.5rem">
        <Box maxWidth="80rem" margin="auto">
          <AllDayTasks day={selectedDay} />
          <Timeline day={selectedDay} />
        </Box>
      </Content>

      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: "fixed", bottom: "1rem", right: "1rem" }}
        onClick={() => toggleIsShowAddTaskModal(null)}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
};
