import { type ThemeMode } from "@structured/utils/colors";
import { TableName } from "@structured/utils/rxdb";
import {
  type RxSettingsEntryNaked,
  useDb,
  validateDocs,
} from "@structured/utils/rxdb";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import localforage from "localforage";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useSession } from "../session";

export interface SettingsState {
  settings: RxSettingsEntryNaked | null;
  themeMode: ThemeMode;
  isValid: boolean;
  isLoading: boolean;
}

interface SettingsContextProps extends SettingsState {
  updateThemeMode: (mode: ThemeMode) => Promise<void>;
  initSettings: () => Promise<void>;
  updateSettings: (
    userId: string,
    changes: Partial<RxSettingsEntryNaked>
  ) => Promise<void>;
}

const initialState: SettingsState = {
  settings: null,
  themeMode: "system",
  isValid: true,
  isLoading: true,
};

// Create context
const SettingsContext = createContext<SettingsContextProps>({
  ...initialState,
  initSettings: null,
  updateSettings: null,
  updateThemeMode: null,
});

export const SettingsProvider: React.FC<{
  readonly children: React.ReactNode;
}> = ({ children }) => {
  const [state, setState] = useState(initialState);
  const session = useSession();

  const { db, isBooting } = useDb();

  useEffect(() => {
    localforage
      .getItem<ThemeMode>("themeMode")
      .then(
        (themeMode) =>
          themeMode &&
          setState((prev) => ({
            ...prev,
            themeMode,
          }))
      )
      .catch(console.error);

    if (!isBooting && db) {
      const subscription = db.settings
        .find({ sort: [{ created_at: "asc" }] })
        .$.subscribe((documents) => {
          const [firstDoc] = documents;
          setState((prev) => ({
            ...prev,
            isLoading: false,
            settings: firstDoc?.toMutableJSON(),
            isValid: firstDoc
              ? validateDocs([firstDoc], TableName.Settings)
              : true,
          }));
        });

      return () => subscription.unsubscribe();
    }
  }, [isBooting, db]);

  const updateThemeMode = async (mode: ThemeMode) => {
    try {
      await localforage.setItem("themeMode", mode);
      setState((prev) => ({ ...prev, themeMode: mode }));
    } catch (error) {
      console.error(error);
    }
  };

  const initSettings = async () => {
    try {
      await db.settings.upsert({
        user_id: session.user.id,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        analytics_id: uuid(),
        _deleted: false,
      });
    } catch (e) {
      console.error(e);
    }
  };

  const updateSettings = async (
    userId: string,
    changes: Partial<RxSettingsEntryNaked>
  ) => {
    try {
      const doc = await db.settings.findOne(userId).exec();
      if (doc) {
        await doc.incrementalPatch({
          ...changes,
          modified_at: new Date().toISOString(),
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        ...state,
        updateThemeMode,
        initSettings,
        updateSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = () => {
  return useContext(SettingsContext);
};