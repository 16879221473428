import CloseIcon from "@mui/icons-material/Close";
import TimelapseOutlinedIcon from "@mui/icons-material/TimelapseOutlined";
import { Box, Button, FormControlLabel, IconButton, MenuItem, Popover, Select, Typography } from "@mui/material";
import { Icon } from "@structured/components/Icon";
import { type TmpTask } from "@structured/utils/tasks";
import React, { useState } from "react";
import { type Control, Controller, type UseFormSetValue } from "react-hook-form";

import { type FormValues } from "../TaskForm";
import { TaskRowIcon } from "./shared/TaskRowIcon";
import { TaskFormRowContainer } from "./TaskFormRowContainer";

interface TaskDurationPickerRowProps {
    disabled: boolean;
    task: TmpTask;
    setValue: UseFormSetValue<FormValues>;
    taskDuration: number;
    setTaskDuration: (duration: number) => void;
    control: Control<FormValues>;
}

export const TaskDurationPickerRow = (props: TaskDurationPickerRowProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const presetDurationMinutes = [
        { label: "1m", value: 1 },
        { label: "15m", value: 15 },
        { label: "30m", value: 30 },
        { label: "45m", value: 45 },
        { label: "1hr", value: 60 }
    ];

    const parsedTimeToString = (time: number) => {
        const totalMinutes = Math.floor(time);
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(remainingMinutes).padStart(2, "0");
        return `${formattedHours}hr ${formattedMinutes}m`;
    }
    const parsedTimeToNumber = (time: number, type: string) => {
        const totalMinutes = Math.floor(time);
        const hours = Math.floor(totalMinutes / 60);
        const remainingMinutes = totalMinutes % 60;
        if (type === "hours") {
            return hours;
        }
        return remainingMinutes;
    }
    const updateTaskDuration = (hours: number, minutes: number) => {
        const totalMinutes = Math.max(hours * 60 + minutes, 1);
        props.setTaskDuration(totalMinutes);
        props.setValue("task.duration", totalMinutes);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "custom-duration-popover" : undefined;


    return (
        <TaskFormRowContainer>
            <TaskRowIcon disabled={props.disabled}>
                <TimelapseOutlinedIcon
                    sx={{
                        color: "action",
                        flexShrink: 0,
                        width: "1.25rem",
                        height: "1.25rem",
                    }}
                />
            </TaskRowIcon>
            <Box
                sx={{
                    display: "flex",
                    height: "3.125rem",
                    alignItems: "flex-end",
                    gap: "0.5rem",
                    alignSelf: "stretch",
                    width: "100%",
                }}
            >
                {presetDurationMinutes.map((preset) => (
                    <Controller
                        key={preset.value}
                        control={props.control}
                        name="task.duration"
                        disabled={props.disabled}
                        render={({ field: { onChange, value, ...field } }) => (
                            <Button
                                onClick={(e) => {
                                    onChange(preset.value);
                                    props.setTaskDuration(preset.value);
                                }}
                                disabled={props.disabled}
                                variant={props.taskDuration === preset.value ? "contained" : "outlined"}
                                disableElevation
                                key={preset.value}
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    flex: "1 1 0",
                                    minWidth: 0,
                                    alignSelf: "stretch",
                                }}
                            >
                                <Typography
                                    color={props.task.duration === preset.value ? "white" : "primary"}
                                    variant="body1"
                                    noWrap
                                >
                                    {preset.label}
                                </Typography>
                            </Button>
                            //    <MenuItem
                            //        key={index}
                            //        value={index}
                            //        onClick={(ev) => console.log(ev)}
                            //    >{index}</MenuItem>
                        )}
                    />

                ))}
                <Button
                    onClick={handleClick}
                    disabled={props.disabled}
                    variant={presetDurationMinutes.some((preset) => preset.value === props.taskDuration) ? "outlined" : "contained"}
                    disableElevation
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "stretch",
                        flex: "2 1 0",
                        minWidth: 0,
                    }}
                >
                    {presetDurationMinutes.some((preset) => preset.value === props.taskDuration) ? (
                        <Icon name="more_horiz" />
                    ) : (
                        <Typography variant="body1" noWrap>
                            {parsedTimeToString(props.taskDuration)}
                        </Typography>
                    )}
                </Button>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <Box sx={{ p: 2 }}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "1rem" }} >
                            <Typography
                                fontSize="1rem"
                                fontWeight={500}
                            >
                                Enter custom duration
                            </Typography>
                            <IconButton
                                size="small"
                                onClick={handleClose}
                            >
                                <CloseIcon sx={{ fontSize: "1.5rem" }} />
                            </IconButton>

                        </Box>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Controller
                                control={props.control}
                                name="task.duration"
                                disabled={props.disabled}
                                render={({ field: { onChange, value, ...field } }) => (
                                    <Select
                                        endAdornment={<Typography marginRight={3}>hr</Typography>}
                                        value={parsedTimeToNumber(props.taskDuration, "hours")}
                                        onChange={(ev) => {
                                            const hours = Number(ev.target.value);
                                            const minutes = parsedTimeToNumber(props.taskDuration, "minutes");
                                            onChange(Math.max(hours * 60 + minutes, 1));
                                            updateTaskDuration(hours, minutes);
                                        }}
                                    >
                                        {[...Array(24)].map((_, index) => (
                                            <MenuItem key={index} value={index}>{index}</MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            <Select
                                endAdornment={<Typography marginRight={3}>m</Typography>}
                                value={parsedTimeToNumber(props.taskDuration, "minutes")}
                                onChange={(ev) => updateTaskDuration(parsedTimeToNumber(props.taskDuration, "hours"), Number(ev.target.value))}
                            >
                                {[...Array(60)].map((_, index) => (
                                    <MenuItem key={index} value={index} disabled={parsedTimeToNumber(props.taskDuration, "hours") === 0 && index === 0}>
                                        {index}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                </Popover>
            </Box>
        </TaskFormRowContainer>
    );
}
