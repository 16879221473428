import { Alert, Box, CircularProgress, Container, Grid, Link, Slide, type SlideProps, Snackbar, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { sendMagicLink, verifyOtp } from "@structured/utils/api";
import { emailPattern } from "@structured/utils/validation";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";

import { MobileLoginComponent, StaticSidePanelComponent } from "../LoginComponents";
import DesktopView from "../LoginComponents/DesktopView";
import TabletView from "../LoginComponents/TabletView";
import styles from "./Login.module.scss";

interface LoginFormValues {
  readonly email: string;
  readonly otp: string;
  readonly step: "email" | "otp";
}

interface SnackbarProps {
  message: string;
  open: boolean;
  variant: "success" | "error";
}

export const Login = () => {
  const {
    register,
    control,
    handleSubmit,
    unregister,
    setValue,
    setError,
    resetField,
    watch,
    formState: { isValid },
  } = useForm<LoginFormValues, unknown, LoginFormValues>({
    defaultValues: { otp: "" },
  });

  const [buttonLoading, setButtonLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<SnackbarProps>({ message: "", open: false, variant: "success" });
  const [isMobileLockout, setMobileLockout] = useState(true);
  const [showLandscapeError, setShowLandscapeError] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);

  const theme = useTheme();

  const onSubmit = handleSubmit(async (data) => {
    switch (watchStep) {
      case "email": {
        setButtonLoading(true);
        await sendMagicLink(data.email);
        unregister("email", { keepValue: true });
        setValue("step", "otp");
        setButtonLoading(false);
        setResendCooldown(30);
        return;
      }

      case "otp": {
        setButtonLoading(true);
        const response = await verifyOtp(data.email, data.otp);
        if (response.error) {
          resetField("otp");
          setError("otp", { message: "Invalid OTP" });
          setSnackbar({
            message: "Invalid OTP. Please try again.",
            open: true,
            variant: "error",
          });
          setButtonLoading(false);
          return;
        }
        setButtonLoading(false);
      }
    }
  });

  const resendCodeHandler = async () => {
    if (resendCooldown > 0) return;

    resetField("otp");
    setButtonLoading(true);
    await sendMagicLink(watch("email"));
    unregister("email", { keepValue: true });
    setValue("step", "otp");
    setButtonLoading(false);
    setError("otp", { message: "Code resent" });
    setSnackbar({
      message: "We've resent the code to your email.",
      open: true,
      variant: "success",
    });
    setResendCooldown(30);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendCooldown > 0) {
      timer = setInterval(() => {
        setResendCooldown((prevCooldown) => prevCooldown - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [resendCooldown]);

  const watchStep = watch("step", "email");

  const navigate = useNavigate();

  const isMobileDevice = useMediaQuery("(max-width: 600px)");
  const isMobileLandscape = useMediaQuery("(max-height:600px) and (orientation: landscape)");
  const isTabletDevice = useMediaQuery("(max-width:1024px)");

  const gradient = `linear-gradient(180deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`
  const footerLinks = [
    {
      title: "Privacy Policy",
      link: "https://strct.app/privacy_policy",
    },
    {
      title: "Terms of Service",
      link: "https://strct.app/tos",
    },
    {
      title: "Data Processing Agreement",
      link: "https://strct.app/dpa",
    },
  ];

  const loginForm = (
    <Box className={styles.Login__Container}>
      <form onSubmit={onSubmit}>
        {watchStep === "email" && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "0.75rem" }}>
            <InputLabel>
              <Typography
                textAlign={"left"}
                variant="body1"
                color="text.primary"
              >
                Enter your email to start planning
              </Typography>
            </InputLabel>
            <TextField
              type="email"
              placeholder="Your email address"
              fullWidth
              {...register("email", {
                required: true,
                pattern: emailPattern,
              })}
            />
          </Box>
        )}
        {watchStep === "otp" && (
          <Box sx={{ display: "flex", width: "21.875rem", flexDirection: "column", alignItems: "flex-start", gap: "0.75rem" }}>
            <InputLabel>
              <Typography
                textAlign="left"
                variant="body1"
                color="text.primary"
                marginBottom={1}
                whiteSpace="pre-wrap"
                display="inline"
              >
                We've sent a code to <Typography display="inline" fontWeight="500">{watch("email")}</Typography>
              </Typography>
            </InputLabel>
            <Controller
              name="otp"
              control={control}
              rules={{ validate: (value) => value.length === 6 }}
              render={({ field, fieldState }) => (
                <Box>
                  <OtpInput
                    numInputs={6}
                    value={field.value}
                    onChange={field.onChange}
                    renderInput={(props) => <input {...props} />}
                    containerStyle={{ display: "flex", gap: "0.625rem" }}
                    inputStyle={{
                      borderRadius: "0.5rem",
                      background: "transparent",
                      width: "3.125rem",
                      height: "3.125rem",
                      outline: "none",
                      border: theme.palette.mode === "dark" ? "0.0625rem solid rgba(235, 235, 245, 0.3)" : "0.0625rem solid rgba(60, 60, 67)",
                      fontFamily: "Inter",
                      fontSize: "1rem",
                      fontWeight: 500,
                      lineHeight: "2.25rem",
                      textAlign: "center",
                    }}
                    inputType="tel"
                  />
                </Box>
              )}
            />
          </Box>
        )}
        <Box className={styles.Login__Footer}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
            {watchStep === "otp" && (
              <Button
                disableElevation={true}
                color="primary"
                variant="outlined"
                fullWidth
                type="button"
                sx={{ justifyContent: "center", alignItems: "center", gap: "0.5rem", alignSelf: "stretch", shadow: "none" }}
                onClick={() => {
                  setValue("step", "email");
                  resetField("otp");
                }}
                size="large"
              >
                Cancel
              </Button>
            )}
            <Button
              disableElevation={true}
              color="primary"
              variant="contained"
              disabled={!isValid || buttonLoading || (watchStep === "otp" && !watch("otp"))}
              fullWidth
              type="submit"
              sx={{ justifyContent: "center", alignItems: "center", gap: "0.5rem", height: "3.5rem" }}
              size="large"
            >
              {buttonLoading ? <CircularProgress size={24} /> : watchStep === "email" ? "Get Started" : "Verify"}
            </Button>
          </Box>
        </Box>
        <Typography
          textAlign="center"
          variant="body2"
          color="text.primary"
          fontSize={12}
          marginTop={1}
        >
          {watchStep === "email" ? (
            <>By continuing, you're signing up to <Link target="_blank" color="inherit" underline="always" href="https://strct.app/cloud">Structured Cloud</Link>.</>
          ) : (
            <>
              Did not receive a code?{" "}
              {resendCooldown > 0 ? (
                <span>Resend in {resendCooldown}s</span>
              ) : (
                <Link sx={{ cursor: "pointer" }} underline="always" onClick={() => { void resendCodeHandler() }}>
                  Resend Code
                </Link>
              )}
            </>
          )}
        </Typography>
      </form>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbar.open}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        message={snackbar.message}
        autoHideDuration={5000}
        TransitionComponent={SlideTransition}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.variant}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );

  const mobileView = (
    <Grid item xs={12}>
      <MobileLoginComponent
        gradient={gradient}
        textColor={isMobileLandscape ? "black" : theme.palette.text.primary}
        setMobileLockout={() => {
          setMobileLockout(false);
        }}
        isLandScape={isMobileLandscape}
      />
    </Grid>
  );

  useEffect(() => {
    setShowLandscapeError(isMobileLandscape);
  }, [isMobileLandscape]);

  if (showLandscapeError && isMobileLockout) {
    return (<>{mobileView}</>)
  }

  return (
    <>
      {isMobileDevice && isMobileLockout ? (
        <>{mobileView}</>
      ) : (
        <Container disableGutters maxWidth={false}>
          <Grid container spacing={0}>
            {!isTabletDevice && (
              <Grid item xs={6}>
                <StaticSidePanelComponent
                  gradient={gradient}
                />
              </Grid>
            )}
            {isTabletDevice ? (
              <TabletView
                gradient={gradient}
                loginForm={loginForm}
                footerLinks={footerLinks}
              />
            ) : (
              <DesktopView
                gradient={gradient}
                loginForm={loginForm}
                footerLinks={footerLinks}
              />
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}