import InboxIcon from "@mui/icons-material/Inbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

export const InboxHeader: React.FC = () => {
  return (
    <Box>
      <Typography
        display="flex"
        alignItems="center"
        gap="1rem"
        variant="h3"
        fontWeight={700}
      >
        <InboxIcon />
        Inbox
      </Typography>
    </Box>
  );
};
