import { Box, Card } from "@mui/material";
import { ICON_CATEGORIES } from "@structured/utils/icons";
import { useRef, useState } from "react";
import { useDebouncyFn } from "use-debouncy";

import { IconPicker } from "./IconPicker";
import { IconPickerHeader } from "./IconPickerHeader";

export const IconPickerPopover = (props: {
  selectedIconId: string | null;
  onClose: () => void;
  onSelectIcon: (iconId: string | null) => void;
}) => {
  const [visibleSection, setVisibleSection] = useState(ICON_CATEGORIES[0].id);
  const ref = useRef<HTMLDivElement>(null);

  const handleVisibleCategory = useDebouncyFn(
    (categoryId: string) => setVisibleSection(categoryId),
    10
  );

  const handleTabSelect = (categoryId: string) => {
    if (!ref) {
      return;
    }

    ref.current
      .querySelector(`#${categoryId.replace(/\./g, "\\.")}`)
      ?.scrollIntoView({ block: "nearest", behavior: "auto" });

    handleVisibleCategory(categoryId);
  };

  return (
    <>
      <Card
      >
        <Box sx={{ p: "1rem", height: "50%", overflowY: "auto" }}>
          <IconPickerHeader
            onTabSelect={handleTabSelect}
            selectedTab={visibleSection}
          />
          <IconPicker
            ref={ref}
            selectedIconId={props.selectedIconId}
            onSelectIcon={(icon) => props.onSelectIcon(icon)}
            onVisibleCategory={setVisibleSection}
          />
        </Box>
      </Card>
    </>
  );
};
