import CircleChecked from "@mui/icons-material/CheckCircle";
import CircleOutlined from "@mui/icons-material/CircleOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TimelineContent from "@mui/lab/TimelineContent";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { getThemeColor } from "@structured/utils/colors";
import { useTask } from "@structured/utils/hooks";
import { type TimelineTaskEntry } from "@structured/utils/timeline";
import classNames from "classnames";
import React from "react";

import { TaskSummary } from "./TaskSummary";
import styles from "./TimelineTask.module.scss";

export interface TimelineTaskProps {
  readonly entry: TimelineTaskEntry;
  readonly onExpand?: (expanded: boolean) => void;
  readonly expanded?: boolean;
}

export const TimelineTask: React.FC<TimelineTaskProps> = ({
  entry,
  onExpand,
  expanded,
}) => {
  const taskContext = useTask(entry.taskOrRecurring, entry.start);

  return (
    <TimelineContent
      sx={{
        ".MuiTimelineItem-root:hover &": {
          background: (theme) =>
            getThemeColor(taskContext.task.color, theme, "light"),
        },
      }}
      className={styles.TimelineTask__ContentWrapper}
    >
      {!taskContext.subtasks?.length ? (
        <div className={styles.TimelineTask}>
          <TaskSummary {...taskContext} />
          <div onClick={(e) => e.stopPropagation()}>
            <Checkbox
              sx={{ height: "max-content" }}
              onChange={taskContext.toggleCompletedAt}
              icon={
                <CircleOutlined
                  sx={{
                    color: (theme) =>
                      getThemeColor(taskContext.task.color, theme),
                  }}
                />
              }
              checkedIcon={
                <CircleChecked
                  sx={{
                    color: (theme) =>
                      getThemeColor(taskContext.task.color, theme),
                  }}
                />
              }
              checked={!!taskContext.completedAt}
            />
          </div>
        </div>
      ) : (
        <Accordion onChange={(_, e) => onExpand?.(e)}>
          <AccordionSummary
            className={classNames(styles.TimelineTask, {
              [styles.isOverlapBefore]: entry.isOverlapBefore,
              [styles.isOverlapAfter]: entry.isOverlapAfter,
            })}
          >
            <TaskSummary {...taskContext} />
            <AccordionActions
              sx={{
                transform: expanded ? "rotate(180deg)" : "none",
                transition: (theme) => theme.transitions.create("transform"),
              }}
            >
              <IconButton>
                <ExpandMoreIcon />
              </IconButton>
            </AccordionActions>
            <Box onClick={(e) => e.stopPropagation()}>
              <Checkbox
                sx={{ height: "max-content" }}
                onChange={taskContext.toggleCompletedAt}
                icon={
                  <CircleOutlined
                    sx={{
                      color: (theme) =>
                        getThemeColor(taskContext.task.color, theme),
                    }}
                  />
                }
                checkedIcon={
                  <CircleChecked
                    sx={{
                      color: (theme) =>
                        getThemeColor(taskContext.task.color, theme),
                    }}
                  />
                }
                checked={!!taskContext.completedAt}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingY: "1rem" }}>
            <Divider
              sx={{
                width: "90%",
                marginBottom: "0.5rem",
              }}
            />
            {taskContext.subtasks.map((subtask) => (
              <Box key={subtask.id} component="fieldset">
                <FormControlLabel
                  label={subtask.title}
                  control={
                    <Checkbox
                      size="small"
                      checked={!!subtask.completed_at}
                      onChange={(_, checked) =>
                        taskContext.updateSubtask(subtask.id ?? "", {
                          completed_at: checked
                            ? new Date().toISOString()
                            : null,
                        })
                      }
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                />
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </TimelineContent>
  );
};
