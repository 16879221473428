import { getIsoStrDateOnly } from "../date/getIsoStrDateOnly";
import { type RxRecurringOccurrenceNaked } from "../rxdb/schemas/recurringOccurrence.schema";

export const hasDetachedTasksOnDayForRecurring = (
  allRecurringOccurrences: RxRecurringOccurrenceNaked[],
  day: Date,
  recurringId: string
): boolean => {
  return !!allRecurringOccurrences.find(
    (occurrence) =>
      occurrence.is_detached &&
      occurrence.recurring === recurringId &&
      occurrence.day === getIsoStrDateOnly(day)
  );
};
