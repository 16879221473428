import { TableName } from "@structured/utils/rxdb";
import {
  type RxRecurringNaked,
  useDb,
  validateDocs,
} from "@structured/utils/rxdb";
import { addLocalTimeToTask, RecurringType } from "@structured/utils/tasks";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import dayjs from "dayjs";
import React, { createContext, useContext, useEffect, useState } from "react";
import { tap } from "rxjs";

import { useSettings } from "../settings";
import {
  ONBOARDING_TASK_SLEEP,
  ONBOARDING_TASK_WAKE_UP,
} from "./onboardingTasks.const";

export interface Recurring extends RxRecurringNaked {
  localStartDay?: string;
  localEndDay?: string;
  localStartTime?: number;
}

interface RecurringContextProps {
  recurringTasks: Recurring[];
  isLoading: boolean;
  isValid: boolean;
  addRecurring: (recurring: Recurring) => Promise<void>;
  removeRecurring: (id: string) => Promise<void>;
  updateRecurring: (id: string, changes: Partial<Recurring>) => Promise<void>;
  createOnboardingTasks: (userId: string) => Promise<void>;
}

const initialState: RecurringContextProps = {
  recurringTasks: [],
  isLoading: true,
  isValid: true,
  addRecurring: null,
  removeRecurring: null,
  updateRecurring: null,
  createOnboardingTasks: null,
};

const WEEKDAYS: (keyof Recurring)[] = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const RecurringContext = createContext<RecurringContextProps>(initialState);

export const RecurringProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [recurringTasks, setRecurringTasks] = useState<Recurring[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const { db, isBooting: isFetching } = useDb();
  const { updateSettings } = useSettings();
  useEffect(() => {
    if (!isFetching && db) {
      setIsLoading(true);

      const subscription = db.recurring
        .find({ sort: [] })
        .$.subscribe((documents) => {
          setIsValid(validateDocs(documents, TableName.Recurring));
          setRecurringTasks(
            documents.map((doc) => addLocalTimeToTask(doc.toMutableJSON()))
          );
          setIsLoading(false);
        });

      return () => subscription.unsubscribe();
    }
  }, [db, isFetching]);

  const recurringModelClean = <T extends Partial<Recurring>>({
    localEndDay,
    localStartDay,
    localStartTime,
    ...recurringTask
  }: T): Partial<RxRecurringNaked> => {
    let body = {
      ...WEEKDAYS.reduce(
        (prev, weekday) => ({
          ...prev,
          [weekday]: false,
        }),
        {}
      ),
      end_day: null,
      ...recurringTask,
    };

    if ("received_at" in body) {
      delete body.received_at;
    }
    if ("replication_revision" in body) {
      delete body.replication_revision;
    }

    if (
      body.recurring_type === RecurringType.Weekly &&
      WEEKDAYS.every((weekday) => body[weekday] === false)
    ) {
      console.warn("No weekdays selected – not saving");
      body = {
        ...body,
        interval: body.interval ?? 1,
        monday: true,
      };
    }

    if (body.recurring_type === RecurringType.Daily) {
      body = {
        ...body,
        ...WEEKDAYS.reduce(
          (prev, weekday) => ({
            ...prev,
            [weekday]: true,
          }),
          {}
        ),
      };
    }

    return body;
  };

  const addRecurring = async (recurringTask: Recurring) => {
    try {
      await db.recurring.insert(
        recurringModelClean({ ...recurringTask }) as RxRecurringNaked
      );
    } catch (e) {
      console.error(e);
    }
  };

  const removeRecurring = async (id: string) => {
    try {
      const doc = await db.recurring.findOne(id).exec();
      if (doc) {
        await doc.remove();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateRecurring = async (id: string, changes: Partial<Recurring>) => {
    try {
      const doc = await db.recurring.findOne(id).exec();
      if (doc) {
        await doc.incrementalPatch(recurringModelClean({ ...changes }));
      }
    } catch (e) {
      console.error(e);
    }
  };

  const createOnboardingTasks = async (userId: string) => {
    const parsed = {
      wakeUpTime: 8,
      sleepTime: 22,
    };

    await Promise.all([
      addRecurring({
        ...ONBOARDING_TASK_WAKE_UP,
        id: uuid(),
        user_id: userId,
        start_time: parsed.wakeUpTime,
        modified_at: new Date().toISOString(),
        created_at: new Date().toISOString(),
        start_day: dayjs(new Date()).format("YYYY-MM-DD"),
      }),
      addRecurring({
        ...ONBOARDING_TASK_SLEEP,
        id: uuid(),
        user_id: userId,
        start_time: parsed.sleepTime,
        modified_at: new Date().toISOString(),
        created_at: new Date().toISOString(),
        start_day: dayjs(new Date()).format("YYYY-MM-DD"),
      }),
      updateSettings(userId, {
        wake_up_time: parsed.wakeUpTime,
        did_complete_onboarding: true,
      }),
    ]);
  };

  return (
    <RecurringContext.Provider
      value={{
        recurringTasks,
        isLoading,
        isValid,
        addRecurring,
        removeRecurring,
        updateRecurring,
        createOnboardingTasks,
      }}
    >
      {children}
    </RecurringContext.Provider>
  );
};

export const useRecurringTasks = () => useContext(RecurringContext);
