import { TableName } from "@structured/utils/rxdb";
import { type RxTaskNaked, useDb, validateDocs } from "@structured/utils/rxdb";
import { addLocalTimeToTask } from "@structured/utils/tasks";
import React, { createContext, useContext, useEffect, useState } from "react";

export interface Task extends RxTaskNaked {
  localDay?: string;
  localStartTime?: number;
}

interface TaskState {
  tasks: Task[];
  isLoading: boolean;
  isValid: boolean;
}

interface TaskContextProps extends TaskState {
  addTask: (task: Task) => Promise<void>;
  removeTask: (id: string) => Promise<void>;
  updateTask: (id: string, changes: Partial<Task>) => Promise<void>;
  getVisibleTasks: () => Task[];
  getInboxTasks: (filter: "all" | "complete" | "incomplete") => Task[];
}

const initialState: TaskState = {
  tasks: [],
  isLoading: true,
  isValid: true,
};

const TaskContext = createContext<TaskContextProps>({
  ...initialState,
  addTask: null,
  removeTask: null,
  updateTask: null,
  getVisibleTasks: null,
  getInboxTasks: null,
});

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const { db, isBooting: isFetching } = useDb();

  useEffect(() => {
    if (!isFetching && db) {
      setIsLoading(true);
      const subscription = db.task
        .find({ sort: [] })
        .$.subscribe((documents) => {
          setIsValid(validateDocs(documents, TableName.Task));
          setTasks(
            documents.map((doc) => addLocalTimeToTask(doc.toMutableJSON()))
          );
          setIsLoading(false);
        });

      return () => subscription.unsubscribe();
    }
  }, [db, isFetching]);

  const cleanModel = <T extends Partial<Task>>({
    localDay,
    localStartTime,
    ...task
  }: T): RxTaskNaked => {
    const copy = { ...task };

    if (copy.start_time) {
      copy.order_index = null;
    }

    if (copy.subtasks) {
      copy.subtasks = copy.subtasks.filter(
        (st) => st.title && st.title.trim().length > 1
      );

      if (!copy.subtasks) {
        copy.subtasks = [];
      }
    }

    if (copy.is_all_day) {
      copy.start_time = null;
      copy.order_index = 0;
    }

    if (copy.is_in_inbox) {
      copy.day = null;
      copy.start_time = null;
      copy.is_all_day = false;
    }

    if ("received_at" in copy) {
      delete copy.received_at;
    }

    if ("replication_revision" in copy) {
      delete copy.replication_revision;
    }

    return copy as RxTaskNaked;
  };

  const addTask = async (task: Task) => {
    try {
      await db.task.insert(cleanModel(task));
    } catch (e) {
      console.error(e);
    }
  };

  const removeTask = async (id: string) => {
    try {
      const doc = await db.task.findOne(id).exec();
      if (doc) {
        await doc.remove();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateTask = async (id: string, changes: Partial<Task>) => {
    try {
      const doc = await db.task.findOne(id).exec();
      if (doc) {
        await doc.incrementalPatch(
          cleanModel({
            ...changes,
            modified_at: new Date().toISOString(),
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const getVisibleTasks = () => tasks.filter((task) => !task.is_hidden);
  const getInboxTasks = (filter: "all" | "incomplete" | "complete" = "all") =>
    tasks.filter(
      (task) =>
        task.is_in_inbox &&
        (filter === "all"
          ? true
          : (filter === "complete" && !!task.completed_at) ||
          (filter === "incomplete" && !task.completed_at))
    );

  return (
    <TaskContext.Provider
      value={{
        tasks,
        isLoading,
        isValid,
        addTask,
        removeTask,
        updateTask,
        getVisibleTasks,
        getInboxTasks,
      }}
    >
      {children}
    </TaskContext.Provider>
  );
};

export const useTasks = () => useContext(TaskContext);
