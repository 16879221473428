import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ModalWrapper } from "@structured/components";

export const RecurringDeletePromptModal = (props: {
  onClose: () => void;
  onDeleteAll: () => void;
  onDeleteSingle: () => void;
  onDeleteFuture: () => void;
}) => {
  return (
    <ModalWrapper open={true} onClose={() => props.onClose()}>
      <Box sx={{ maxWidth: "20rem" }}>
        <Typography sx={{ paddingY: "1rem" }}>
          Choose which tasks you would like to apply the changes to.
        </Typography>
        <Box display="flex" flexDirection="column" gap="1rem">
          <Button fullWidth variant="outlined" onClick={props.onDeleteAll}>
            Delete all
          </Button>
          <Button fullWidth variant="outlined" onClick={props.onDeleteFuture}>
            Delete all future tasks
          </Button>
          <Button fullWidth variant="outlined" onClick={props.onDeleteSingle}>
            Delete this instance only
          </Button>
        </Box>
      </Box>
    </ModalWrapper>
  );
};
