import { type RxCollection } from "rxdb";

import { type Database } from "../api/database.types";
import { type RxRecurringNaked } from "./schemas/recurring.schema";
import { type RxRecurringOccurrenceNaked } from "./schemas/recurringOccurrence.schema";
import { type RxSettingsEntryNaked } from "./schemas/settings.schema";
import { type RxTaskNaked } from "./schemas/task.schema";

export type ReplicationFields =
  | "_deleted"
  | "replication_revision"
  | "received_at";

export interface RxGenericReplicationDocumentNaked {
  // id?: string;
  user_id: string;
  replication_revision?: string;
  received_at?: string;
}

// SETTINGS
export type DbSettings = Database["public"]["Tables"]["settings"]["Row"];
type RxSettingsCollection = RxCollection<RxSettingsEntryNaked, {}, {}, {}>;

// TASK
export type DbTask = Database["public"]["Tables"]["task"]["Row"];
// eslint-disable-next-line @typescript-eslint/ban-types
type RxTaskCollection = RxCollection<RxTaskNaked, {}, {}, {}>;

// RECURRING
export type DbRecurring = Database["public"]["Tables"]["recurring"]["Row"];
// eslint-disable-next-line @typescript-eslint/ban-types
type RxRecurringCollection = RxCollection<RxRecurringNaked, {}, {}, {}>;

// RECURRING OCCURRENCE
export type DbRecurringOccurrence =
  Database["public"]["Tables"]["recurring_occurrence"]["Row"];
// eslint-disable-next-line @typescript-eslint/ban-types
type RxRecurringOccurrenceCollection = RxCollection<
  RxRecurringOccurrenceNaked,
  {},
  {},
  {}
>;

export type RxAllCollections = {
  settings: RxSettingsCollection;
  task: RxTaskCollection;
  recurring: RxRecurringCollection;
  recurring_occurrence: RxRecurringOccurrenceCollection;
};
export const RECEIVED_AT_FIELD = "received_at";

export type CheckpointType = {
  readonly name: string;
  readonly checkpointUpdate: number;
};

export interface StandardReplicationModel {
  readonly replication_revision?: string;
  readonly [RECEIVED_AT_FIELD]?: string;
}

export interface SchemaVersionKeys {
  readonly readVersion: number;
  readonly writeVersion: number;
}

export enum TableName {
  Task = "task",
  Recurring = "recurring",
  RecurringOccurrence = "recurring_occurrence",
  Settings = "settings",
}
