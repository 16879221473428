import { Box, Container, Link, useMediaQuery, useTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import appStoreBadge from "@structured/assets/signup-page-images/appStoreBadge.svg";
import panelElements_mobile from "@structured/assets/signup-page-images/panelElements_mobile.svg";
import playStoreBadge from "@structured/assets/signup-page-images/playStoreBadge.png";
import transitionImage from "@structured/assets/signup-page-images/transitionImage.png";
import mobileLogo from "@structured/assets/signup-page-images/white_logo.svg";

export const MobileLoginComponent = (
    props: {
        gradient: string;
        textColor?: string;
        setMobileLockout: (value: boolean) => void;
        isLandScape: boolean;
    }
) => {
    const theme = useTheme();
    return (
        <Container disableGutters maxWidth={false} sx={{ height: props.isLandScape ? "500px" : "100vh", overflow: "hidden" }}>
            <Box
                sx={{
                    height: "100%",
                    background: props.gradient,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    overflow: "hidden",
                }}
            >
                <Box
                    component="img"
                    src={transitionImage}
                    sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        userSelect: "none",
                        zIndex: 1,
                    }}
                />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        zIndex: 2,
                        position: "relative",
                        marginTop: props.isLandScape ? "0" : "5rem",
                        flex: "1 0 auto",
                        justifyContent: "center",
                        padding: !props.isLandScape ? "0" : "1rem 2.5rem",
                    }}
                >
                    <Box
                        component="img"
                        height="3.75rem"
                        width="3.75rem"
                        marginBottom="1.0rem"
                        alt="Structured Logo"
                        src={mobileLogo}
                    />
                    <Box>
                        <Typography
                            textAlign="center"
                            fontSize="2.25rem"
                            fontWeight={400}
                            lineHeight="normal"
                            color="white"
                        >
                            Welcome to
                        </Typography>
                        <Typography
                            textAlign="center"
                            fontSize="3rem"
                            fontWeight={700}
                            lineHeight="normal"
                            color="white"
                        >
                            Structured
                        </Typography>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "1.25rem", width: "16.625rem", marginTop: "5.0rem" }} >
                        <Typography
                            textAlign="center"
                            fontSize="1.25rem"
                            fontWeight={700}
                            lineHeight="1.5rem"
                            color="white"
                        >
                            Download The App Now
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", alignSelf: "stretch", justifyContent: "center", gap: "0.75rem" }}>
                            <Link href="https://play.google.com/store/apps/details?id=io.unorderly.structured" target="_blank" rel="noreferrer">
                                <Box component="img" src={playStoreBadge} alt="Play Store" style={{ width: "8.5rem", height: "2.5rem" }} />
                            </Link>
                            <Link href="https://apps.apple.com/us/app/structured-daily-planner/id1499198946" target="_blank" rel="noreferrer">
                                <Box component="img" src={appStoreBadge} alt="App Store" style={{ width: "7.5rem", height: "2.5rem" }} />
                            </Link>
                        </Box>
                        <Box>

                            <Typography
                                textAlign="center"
                                fontSize="0.875rem"
                                variant="body2"
                                color={props.textColor}
                            >
                                Structured Web is not optimized for mobile yet.{" "}
                            </Typography>

                            <Typography
                                textAlign="center"
                                onClick={() => props.setMobileLockout(false)}
                                fontSize="0.875rem"
                                fontWeight={600}
                                variant="body2"
                                sx={{ cursor: "pointer", textDecoration: "underline" }}
                                color={props.textColor}
                            >
                                Continue anyway
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {!props.isLandScape && (
                    <Box
                        component="img"
                        src={panelElements_mobile}
                        sx={{
                            position: "relative",
                            width: "100%",
                            zIndex: 3,
                        }}
                    />
                )}
            </Box>
        </Container>
    )
}