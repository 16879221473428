import type { RxRecurringNaked } from "@structured/utils/rxdb";
import { useState } from "react";

const weekdays = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export const useSelectedWeekdays = (recurringCfg: RxRecurringNaked) => {
  const getSelectedWeekdays = (recurringCfg: RxRecurringNaked) =>
    Object.keys(recurringCfg ?? {}).filter(
      (key) => weekdays.includes(key) && recurringCfg[key] === true
    );

  const [selectedWeekdays, setSelectedWeekdays] = useState(
    getSelectedWeekdays(recurringCfg)
  );

  return [selectedWeekdays, setSelectedWeekdays] as const;
};
