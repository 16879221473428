import {
  type Task,
  useOccurrences,
  useRecurringTasks,
  useTasks,
} from "@structured/store";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";
import dayjs from "dayjs";

import {
  type RxRecurringNaked,
  type RxRecurringOccurrenceNaked,
  type RxTaskNaked,
  TMP_REV,
} from "../rxdb";
import { isRecurringTask, type TmpSubtask } from "../tasks";
import { useToggleCompletedAt } from "./useToggleCompletedAt";

export const useTaskContextModal = (
  taskDay: Date,
  taskOrRecurring: Task | RxRecurringNaked,
  onClose: () => void
) => {
  const {
    updateOccurrence,
    addOccurrence,
    removeOccurrences,
    getDailyOccurrenceForRecurringTask,
    getOccurrencesForRecurringTask,
  } = useOccurrences();

  const { updateTask, removeTask } = useTasks();
  const { removeRecurring, updateRecurring } = useRecurringTasks();

  const isRecurring = isRecurringTask(taskOrRecurring);
  const isCalendarTask =
    !isRecurring && (taskOrRecurring as Task).events?.length > 0;

  const currentDayRecurringOccurrence: undefined | RxRecurringOccurrenceNaked =
    isRecurring
      ? getDailyOccurrenceForRecurringTask(taskOrRecurring.id, taskDay) ??
        undefined
      : undefined;

  const { toggleCompletedAt } = useToggleCompletedAt(
    taskDay,
    taskOrRecurring,
    currentDayRecurringOccurrence
  );

  const completedAt = currentDayRecurringOccurrence
    ? currentDayRecurringOccurrence.completed_at
    : (taskOrRecurring as RxTaskNaked).completed_at ?? null;

  const allRecurringOccurrencesForCurrent = isRecurring
    ? getOccurrencesForRecurringTask(taskOrRecurring.id)
    : [];

  const subtasks: TmpSubtask[] =
    currentDayRecurringOccurrence?.subtask_occurrences
      ? currentDayRecurringOccurrence.subtask_occurrences
          .map((sto) => {
            const originalSubtask = taskOrRecurring.subtasks.find(
              (st) => st.id === sto.subtask
            );
            if (!originalSubtask) {
              console.warn("Unable to find original subtask for instance");
              return {
                id: "NOOO_SUB",
                title: "unable to find original subtask for instance ;(",
                completed_at: null,
              };
            }

            return {
              id: originalSubtask.id,
              completed_at: sto.completed_at ?? null,
              title: originalSubtask.title,
            };
          })
          .filter((sto) => sto.id !== "NOOO_SUB")
      : (taskOrRecurring.subtasks as TmpSubtask[]);

  const updateSubtasks = (targetId: string, changes: Partial<TmpSubtask>) => {
    const updatedSubtasks = subtasks.map((sti, index) => {
      return sti.id === targetId
        ? {
            ...sti,
            ...changes,
          }
        : sti;
    });

    if (isRecurring) {
      console.log("NOT IMPLEMENTED FOR RECURRING");
      const subtaskOccurrences = updatedSubtasks.map((tmpSubtask) => ({
        id: uuid(),
        subtask: tmpSubtask.id,
        completed_at: tmpSubtask.completed_at,
      }));
      if (currentDayRecurringOccurrence) {
        void updateOccurrence(currentDayRecurringOccurrence.id, {
          subtask_occurrences: subtaskOccurrences,
        });
      } else {
        void addOccurrence({
          id: uuid(),
          user_id: taskOrRecurring.user_id,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          recurring: taskOrRecurring.id,
          day: dayjs(taskDay).format("YYYY-MM-DD"),
          replication_revision: TMP_REV,
          order_index: null,
          completed_at: null,
          detached_task: null,
          is_detached: false,
          _deleted: false,
          subtask_occurrences: subtaskOccurrences,
        });
      }
    } else {
      void updateTask(taskOrRecurring.id, {
        subtasks: updatedSubtasks,
      });
    }
  };

  const deleteSingleRecurring = () => {
    if (currentDayRecurringOccurrence) {
      void updateOccurrence(currentDayRecurringOccurrence.id, {
        is_detached: true,
      });
    } else {
      void addOccurrence({
        id: uuid(),
        user_id: taskOrRecurring.user_id,
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        recurring: taskOrRecurring.id,
        day: dayjs(taskDay).format("YYYY-MM-DD"),
        subtask_occurrences: [],
        replication_revision: TMP_REV,
        completed_at: null,
        order_index: null,
        detached_task: null,
        is_detached: true,
        _deleted: false,
      });
    }
    onClose();
  };
  const deleteFutureRecurring = () => {
    const dayBeforeDate = dayjs(taskDay).subtract(1, "d");
    const actions: Promise<void>[] = [
      updateRecurring(taskOrRecurring.id, {
        // NOTE only end_day is updated!!!
        end_day: dayBeforeDate.format("YYYY-MM-DD"),
      }),
    ];
    const occurrencesToDelete = allRecurringOccurrencesForCurrent.filter(
      (occurrence) => new Date(occurrence.day) >= taskDay
    );

    if (occurrencesToDelete.length > 0) {
      actions.push(
        removeOccurrences(
          occurrencesToDelete.map((occurrence) => occurrence.id)
        )
      );
    }

    void Promise.all(actions);

    onClose();
  };

  const deleteAllRecurring = () => {
    void removeRecurring(taskOrRecurring.id);
    if (allRecurringOccurrencesForCurrent.length > 0) {
      void removeOccurrences(
        allRecurringOccurrencesForCurrent.map((occurrence) => occurrence.id)
      );
    }
    onClose();
  };

  const deleteSingleTask = () => {
    if (confirm("Are you sure you want to delete the task?")) {
      void removeTask(taskOrRecurring.id);
      onClose();
    }
  };

  const hideTask = () => {
    if (
      confirm("Are you sure you want to hide calendar imported task forever?")
    ) {
      void updateTask(taskOrRecurring.id, { is_hidden: true });
      onClose();
    }
  };

  return {
    isRecurring,
    isCalendarTask,
    taskOrRecurring,
    subtasks,
    completedAt,
    updateSubtasks,
    deleteAllRecurring,
    deleteSingleRecurring,
    deleteFutureRecurring,
    deleteSingleTask,
    hideTask,
    toggleCompletedAt,
  };
};
