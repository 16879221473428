import Box from "@mui/material/Box";
import { useDailyRecurringTasks, useDailyTasks } from "@structured/utils/hooks";

import { type RxTaskNaked } from "../../utils/rxdb/schemas/task.schema";
import { AllDayTask } from "./AllDayTask/AllDayTask";
import styles from "./AllDayTasks.module.scss";

const DEFAULT_ORDER_INDEX = 9999;

export const AllDayTasks = (props: { day: Date }) => {
  const dailyTasks = useDailyTasks(props.day);
  const dailyRecurringTasks = useDailyRecurringTasks(props.day);

  const allDayTasks = [...dailyTasks, ...dailyRecurringTasks]
    .filter((t) => t.is_all_day)
    .sort((a, b) => {
      const aOI = (a as RxTaskNaked).order_index ?? DEFAULT_ORDER_INDEX;
      const bOI = (b as RxTaskNaked).order_index ?? DEFAULT_ORDER_INDEX;

      if (aOI !== bOI) {
        return aOI - bOI; // sort by order_index in ascending order
      } else {
        return (
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        ); // sort by created_at in descending order
      }
    });

  return allDayTasks.length > 0 ? (
    <Box className={styles.AllDayTasks}>
      {allDayTasks.map((t) => (
        <AllDayTask taskOrRecurring={t} key={t.id} day={props.day} />
      ))}
    </Box>
  ) : (
    <></>
  );
};
