import { type Recurring, type Task } from "@structured/store";
import dayjs from "dayjs";

import { getTimelineProgress } from "./getTimelineProgress";
import { type TimelineEntry, TimelineEntryType } from "./timeline.types";

const GAP_PROMPT_MIN_DURATION_IN_MS = 60 * 60 * 1000;
const GAP_BUFFER_MIN_DURATION_IN_MS = 60 * 1000;

export const generateTimelineEntries = (
  tasksForDay: (Task | Recurring)[],
  day: Date,
  now: Date
): TimelineEntry[] => {
  const entries: TimelineEntry[] = [];

  tasksForDay
    .filter(
      (t) =>
        t.localStartTime !== undefined && typeof t.duration === "number" && !t.is_all_day
    )
    .sort(
      (a, b) => a.localStartTime - b.localStartTime || a.duration - b.duration
    )
    .forEach((task) => {
      const prevEntry = entries.at(-1);
      const startDate = dayjs(day)
        .startOf("day")
        .add(task.localStartTime, "hour");
      const endDate = startDate.add(task.duration, "minute");

      const progress = getTimelineProgress(
        now,
        startDate.toDate(),
        endDate.toDate()
      );

      if (prevEntry) {
        const gapInMS = startDate.valueOf() - prevEntry.end.getTime();
        const isInThePast = startDate.valueOf() < now.valueOf();
        const gapStart = prevEntry.end;
        const gapEnd = startDate.toDate();
        const gapInMin = Math.round(gapInMS / 60000);

        const gapProgress = getTimelineProgress(now, gapStart, gapEnd);

        if (gapInMS > GAP_PROMPT_MIN_DURATION_IN_MS && !isInThePast) {
          entries.push({
            type: TimelineEntryType.ADD_TASK_PROMPT,
            duration: gapInMin,
            start: gapStart,
            end: gapEnd,
            progress: gapProgress,
            color: prevEntry.color,
          });
        } else if (gapInMS > GAP_BUFFER_MIN_DURATION_IN_MS) {
          entries.push({
            type: TimelineEntryType.GAP_MSG,
            duration: gapInMin,
            start: gapStart,
            end: gapEnd,
            isInThePast,
            progress: gapProgress,
            color: prevEntry.color,
          });
        }
      }

      const isOverlapBefore =
        prevEntry?.type === TimelineEntryType.TASK &&
        prevEntry.end.getTime() > startDate.valueOf() &&
        endDate.diff(startDate, "minute") > 2;

      if (
        isOverlapBefore &&
        prevEntry &&
        prevEntry.type === TimelineEntryType.TASK
      ) {
        prevEntry.isOverlapAfter = true;
      }

      entries.push({
        type: TimelineEntryType.TASK,
        taskOrRecurring: task,
        duration: task.duration,
        start: startDate.toDate(),
        end: endDate.toDate(),
        isOverlapAfter: false,
        isOverlapBefore,
        progress,
        color: task.color as TimelineEntry["color"],
      });
    });

  return entries;
};