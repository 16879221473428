/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import {
  addRxPlugin,
  createRevision,
  createRxDatabase,
  parseRevision,
  type RxStorage,
} from "rxdb";
import { type RxDatabase } from "rxdb/dist/types/types";
import { RxDBDevModePlugin } from "rxdb/plugins/dev-mode";
import { RxDBLeaderElectionPlugin } from "rxdb/plugins/leader-election";
import { RxDBMigrationPlugin } from "rxdb/plugins/migration-schema";
import { migrateStorage } from "rxdb/plugins/migration-storage";
import { getRxStorageDexie } from "rxdb/plugins/storage-dexie";
import { wrappedValidateAjvStorage } from "rxdb/plugins/validate-ajv";

import { createConflictHandler } from "./functions/conflictHandler";
import { SCHEMA_VERSIONS } from "./rxdb.const";
import { type RxAllCollections,type TableName } from "./rxdb.types";
import {
  RECURRING_OCCURRENCE_SCHEMA,
  RECURRING_SCHEMA,
  type RxRecurringNaked,
  type RxRecurringOccurrenceNaked,
  type RxSettingsEntryNaked,
  type RxTaskNaked,
  SETTINGS_SCHEMA,
  TASK_SCHEMA,
} from "./schemas";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  addRxPlugin(RxDBDevModePlugin);
}
addRxPlugin(RxDBLeaderElectionPlugin);
addRxPlugin(RxDBMigrationPlugin);

let dbPromise: Promise<RxDatabase<RxAllCollections, any, any>> | null = null;

const OLD_DB_PREFIX = "structured-web-app-db-v4";
const NEW_DB_PREFIX = "structured-web-app-db-v5";

export const getDatabase = async (): Promise<RxDatabase<RxAllCollections>> => {
  if (!dbPromise) dbPromise = createDatabase();
  return await dbPromise;
};

export async function resetDatabase() {
  const db = await getDatabase();
  console.log("Delete all user data (rxdb)", await db.remove());
  await createDatabase();
  console.log("Recreated DB");
}

export async function createDatabase(): Promise<RxDatabase<RxAllCollections>> {
  const db = await createRxDatabase<RxAllCollections>({
    name: getNewDatabaseName(),
    storage: wrappedValidateAjvStorage({
      storage: getStorage(),
    }),
    multiInstance: true,
  });

  await migrateStorage({
    database: db as any,
    /**
     * Name of the old database,
     * using the storage migration requires that the
     * new database has a different name.
     */
    oldDatabaseName: getOldDatabaseName(),
    oldStorage: getStorage(),
    batchSize: 500,
    parallel: false,
  });

  await db.addCollections({
    settings: {
      schema: SETTINGS_SCHEMA,
      conflictHandler: createConflictHandler<RxSettingsEntryNaked>(),
      migrationStrategies: {
        1: (doc) => doc,
        2: (doc) => doc,
      },
    },
    task: {
      schema: TASK_SCHEMA,
      conflictHandler: createConflictHandler<RxTaskNaked>(),
      migrationStrategies: {
        1: (doc) => doc,
        2: (doc) => doc,
      },
    },
    recurring: {
      schema: RECURRING_SCHEMA,
      conflictHandler: createConflictHandler<RxRecurringNaked>(),
      migrationStrategies: {
        1: (doc) => doc,
        2: (doc) => doc,
      },
    },
    recurring_occurrence: {
      schema: RECURRING_OCCURRENCE_SCHEMA,
      conflictHandler: createConflictHandler<RxRecurringOccurrenceNaked>(),
      migrationStrategies: {
        1: (doc) => doc,
      },
    },
  });
  const collections = [
    db.settings,
    db.task,
    db.recurring,
    db.recurring_occurrence,
  ];

  /**
   * To make it possible to detect and resolve conflicts,
   * we use a custom field 'replication_revision' that
   * works similar to the rxdb revision and will be automatically updated on each write.
   * @link https://rxdb.info/transactions-conflicts-revisions.html
   */

  collections.forEach((collection) => {
    collection.preInsert((docData) => {
      docData.replication_revision = createRevision(db.token);
      docData.schema_version =
        SCHEMA_VERSIONS[collection.name as TableName].writeVersion;

      return docData;
    }, false);

    collection.preRemove(async (docData) => {
      const hash = await db.hashFunction(JSON.stringify(docData));
      const oldRevHeight = parseRevision(docData.replication_revision).height;

      docData.replication_revision = `${oldRevHeight + 1}-${hash}`;
      docData.schema_version =
        SCHEMA_VERSIONS[collection.name as TableName].writeVersion;

      return docData;
    }, false);

    collection.preSave(async (docData) => {
      const hash = await db.hashFunction(JSON.stringify(docData));
      const oldRevHeight = parseRevision(docData.replication_revision).height;

      docData.replication_revision = `${oldRevHeight + 1}-${hash}`;
      docData.schema_version =
        SCHEMA_VERSIONS[collection.name as TableName].writeVersion;

      return docData;
    }, false);
  });

  return db;
}

function getStorageKey(): string {
  const urlString = window.location.href;
  const url = new URL(urlString);
  let storageKey = url.searchParams.get("storage");
  if (!storageKey) {
    storageKey = "dexie";
  }
  return storageKey;
}

/**
 * Easy toggle of the storage engine via query parameter.
 */
function getStorage(): RxStorage<any, any> {
  const storageKey = getStorageKey();

  if (storageKey === "dexie") {
    return getRxStorageDexie();
  } else {
    throw new Error("storage key not defined " + storageKey);
  }
}

function getOldDatabaseName() {
  return OLD_DB_PREFIX;
}

function getNewDatabaseName() {
  return NEW_DB_PREFIX;
}
