import { FullPageLoadingSpinner } from "@structured/components";
import { useSession, useSettings, useUI } from "@structured/store";
import { startReplicationForAll, useDb } from "@structured/utils/rxdb";
import React, { useEffect, useState } from "react";
import { Outlet, useMatch, useNavigate } from "react-router-dom";
import { combineLatest, map, type Subscription } from "rxjs";

export const Auth: React.FC = () => {
  const [ready, setReady] = useState(false);
  const [loggedOut, setLoggedOut] = useState(false);
  const session = useSession();
  const { settings, isLoading, initSettings } = useSettings();
  const navigate = useNavigate();
  const { db } = useDb();
  const { setIsSyncing } = useUI();
  useEffect(() => {
    if (isLoading || ready) return;
    if (loggedOut) return;
    if (settings === undefined) {
      if (session) {
        void initSettings();
        let replicationState: Subscription;
        startReplicationForAll(db, session.user.id)
          .then((replicationStates) => {
            if (!replicationStates) {
              throw new Error("No replication states created");
            }
            replicationState = combineLatest(
              replicationStates.map((rs) => rs.active$)
            )
              .pipe(map((isActiveStates) => isActiveStates.includes(true)))
              .subscribe((isSyncing) => {
                setIsSyncing(isSyncing);
              });
          })
          .catch(console.error);
      } else {
        setReady(true);
        navigate("/auth/login", { replace: true });
      }
    } else if (!settings.cloud_terms_date) {
      setReady(true);
      navigate("/auth/terms-of-service", { replace: true });
    } else {
      setReady(true);
      navigate("/", { replace: true });
    }
  }, [isLoading, ready, session, settings, navigate, loggedOut]);

  useEffect(() => {
    if (!session && !isLoading && settings !== undefined && !loggedOut) {
      setLoggedOut(true);
      setReady(true);
      navigate("/auth/login", { replace: true });
    }
  }, [session, isLoading, settings, navigate, loggedOut]);

  return (
    <>
      {ready ? <Outlet /> : <FullPageLoadingSpinner />}
    </>
  );
};