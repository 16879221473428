import { DEFAULT_TASK_DURATION, TaskType } from "@structured/utils/common";
import { getIsoStrDateOnly } from "@structured/utils/date";
import { DEFAULT_TASK_SYMBOL_ID } from "@structured/utils/icons";
import { TMP_REV } from "@structured/utils/rxdb";
import { type TmpTask } from "@structured/utils/tasks";
import { uuid } from "@supabase/supabase-js/dist/main/lib/helpers";

export const getNewTmpTask = (): TmpTask => ({
  _deleted: false,
  id: uuid(),
  title: "",
  type: TaskType.Normal,
  symbol: DEFAULT_TASK_SYMBOL_ID,
  is_symbol_set: false,
  color: "day",
  day: getIsoStrDateOnly(new Date()),
  start_time: null,
  timezone: null,
  duration: DEFAULT_TASK_DURATION,
  is_all_day: false,
  is_in_inbox: false,
  note: "",
  order_index: null,
  calendar_day_index: 1,
  is_reminder_detached: false,
  is_hidden: false,
  completed_at: null,
  replication_revision: TMP_REV,
  alerts: [],
  events: [],
  subtasks: [],
  alert_sound: null,
  energy_level: 0,
  schema_version: null,
});
