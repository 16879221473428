import type { TaskColor } from "../colors";
import type { RxRecurringNaked,RxTaskNaked } from "../rxdb";

export enum TimelineEntryType {
  TASK = "TASK",
  ADD_TASK_PROMPT = "ADD_TASK_PROMPT",
  GAP_MSG = "GAP_MSG",
}

interface TimelineEntryCommon {
  type: TimelineEntryType;
  duration: number;
  start: Date;
  end: Date;
  progress: number;
  color: TaskColor;
}

export interface TimelineTaskEntry extends TimelineEntryCommon {
  type: TimelineEntryType.TASK;
  taskOrRecurring: RxTaskNaked | RxRecurringNaked;
  isOverlapBefore: boolean;
  isOverlapAfter: boolean;
}

export interface TimelineAddTaskPromptEntry extends TimelineEntryCommon {
  type: TimelineEntryType.ADD_TASK_PROMPT;
  duration: number;
}

export interface TimelineGapMsgEntry extends TimelineEntryCommon {
  type: TimelineEntryType.GAP_MSG;
  duration: number;
  isInThePast: boolean;
}

export type TimelineEntry =
  | TimelineTaskEntry
  | TimelineAddTaskPromptEntry
  | TimelineGapMsgEntry;
