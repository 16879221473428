import { useSession } from "@structured/store";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export interface ProtectedRouteProps {
  readonly redirectPath?: string;
  readonly children?: React.ReactNode;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  redirectPath = "/auth/login",
}) => {
  const session = useSession();

  if (!session) {
    return <Navigate to={redirectPath} replace={true} />;
  }

  return children ? <>{children}</> : <Outlet />;
};
