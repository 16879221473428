import "./WelcomeToStructuredTextComponent.scss";

import { Box, Typography } from "@mui/material";

interface WelcomeToStructuredTextComponentProps {
    gradient: string;
    logo: string;
}

export const WelcomeToStructuredTextComponent = (props: WelcomeToStructuredTextComponentProps) => {
    return (
        <Box className="welcome-to-structured-text-component">
            <Box className="logo-container">
                <Box component="img" src={props.logo} alt="Structured Logo" className="logo" />
                <Box className="text-container">
                    <Typography className="welcome-text">
                        Welcome to
                    </Typography>
                    <Typography className="structured-text" sx={{ background: props.gradient }}>
                        Structured
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}