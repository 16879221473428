import { type RxTaskNaked } from "../rxdb/schemas/task.schema";
import { type TmpTask } from "./task.types";

type SharedRecurringFieldsForTask = Omit<
  RxTaskNaked,
  | "day"
  | "completed_at"
  | "is_in_inbox"
  | "order_index"
  | "is_reminder_detached"
  | "is_hidden"
  | "calendar_day_index"
  | "events"
>;
export const getSharedRecurringFieldsForTask = (
  task: RxTaskNaked
): SharedRecurringFieldsForTask => {
  return {
    id: task.id,
    title: task.title,
    user_id: task.user_id,
    replication_revision: task.replication_revision,
    created_at: task.created_at,
    modified_at: task.modified_at,
    color: task.color,
    start_time: task.start_time,
    duration: task.duration,
    symbol: task.symbol,
    is_all_day: task.is_all_day,
    received_at: task.received_at,
    is_symbol_set: task.is_symbol_set,
    subtasks: task.subtasks,
    alerts: task.alerts,
    note: task.note,
    timezone: task.timezone,
    type: task.type,
    // NOTE: should always be false, since we never want to convert a deleted instance
    _deleted: false,
  };
};

export const getSharedRecurringFieldsForTmpTask = (
  task: TmpTask
): Omit<
  SharedRecurringFieldsForTask,
  "user_id" | "created_at" | "modified_at"
> => {
  return {
    id: task.id,
    type: task.type,
    title: task.title,
    replication_revision: task.replication_revision,
    color: task.color,
    start_time: task.start_time,
    duration: task.duration,
    symbol: task.symbol,
    is_all_day: task.is_all_day,
    received_at: task.received_at,
    is_symbol_set: task.is_symbol_set,
    subtasks: task.subtasks,
    alerts: task.alerts,
    note: task.note,
    timezone: task.timezone,
    // NOTE: should always be false, since we never want to convert a deleted instance
    _deleted: false,
  };
};
