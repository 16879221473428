import { ThemeProvider, useMediaQuery } from "@mui/material";
import { useSettings } from "@structured/store";
import { getColor } from "@structured/utils/colors";
import React, { useEffect } from "react";

import { useCustomTheme } from "./theme";

export const CustomThemeProvider: React.FC<{ children?: React.ReactNode }> = (
  props
) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const { themeMode, settings } = useSettings();

  const initialThemeMode =
    themeMode === "system" ? (prefersDarkMode ? "dark" : "light") : themeMode;

  const { theme, handlers } = useCustomTheme(
    initialThemeMode,
    settings?.theme ?? "day"
  );

  useEffect(() => {
    handlers.setThemeMode(
      themeMode === "system" ? (prefersDarkMode ? "dark" : "light") : themeMode
    );
  }, [themeMode]);

  useEffect(() => {
    handlers.setThemeColor(settings?.theme);
  }, [settings?.theme]);

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
