import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { type Task } from "@structured/store";
import { getThemeColor } from "@structured/utils/colors";
import { today } from "@structured/utils/date";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import React from "react";

import styles from "./SelectDay.module.scss";

export interface SelectDayProps {
  title: string;
  date: Date;
  isSelected: boolean;
  dayNr: number;
  tasksForDay: (Task | RxRecurringNaked)[];
  onDaySelect: (day: Date) => void;
}

export const SelectDay: React.FC<SelectDayProps> = (props) => {
  return (
    <Box
      title={props.date.toDateString()}
      sx={{
        backgroundColor: props.isSelected ? "primary.light" : "transparent",
        borderColor: props.isSelected ? "primary.main" : "transparent",
        "&:hover": {
          backgroundColor: "primary.light",
          borderColor: "primary.main",
        },

        color:
          +props.date < +today
            ? "text.secondary"
            : props.isSelected
            ? "text.primary"
            : "text.secondary",
      }}
      className={styles.Day}
      onClick={() => props.onDaySelect(props.date)}
    >
      <Typography
        fontWeight={700}
        fontSize="1.5rem"
        sx={{
          color: +props.date < +today ? "text.secondary" : "text.primary",
        }}
      >
        {props.dayNr}
      </Typography>
      <Typography variant="subtitle2">{props.title}</Typography>
      <Box className={styles.DayTasks}>
        {props.tasksForDay.map((task) => (
          <Box
            component="span"
            sx={{
              backgroundColor: (theme) =>
                +props.date < +today
                  ? "text.secondary"
                  : getThemeColor(task.color, theme),
            }}
            key={task.id}
            className={styles.DayTask}
          ></Box>
        ))}
      </Box>
    </Box>
  );
};
