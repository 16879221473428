import { supabase } from "@structured/utils/api";
import { useDb } from "@structured/utils/rxdb";
import type { Session } from "@supabase/supabase-js";
import React, { createContext, useContext, useEffect, useState } from "react";

const SessionContext = createContext<Session>(null);

export const SessionProvider: React.FC<{
  readonly children: React.ReactNode;
}> = ({ children }) => {
  const [session, setSession] = useState<Session>();
  const { db, isBooting: isFetching } = useDb();

  useEffect(() => {
    if (!isFetching && db) {
      supabase.auth.onAuthStateChange((_, latestSession) => {
        setSession(latestSession);
      });
    }
  }, [db, isFetching]);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
