import { Box, Button, Drawer, Fade } from "@mui/material";
import { createTheme, type Theme, ThemeProvider } from "@mui/material";
import { DottedDivider } from "@structured/components";
import {
  type Task,
  useRecurringTasks,
  useSession,
  useSettings,
  useTasks,
  useUI,
} from "@structured/store";
import { getColor } from "@structured/utils/colors";
import { DEFAULT_THEME_COLOR } from "@structured/utils/common";
import { type RxRecurringNaked } from "@structured/utils/rxdb";
import {
  getSharedRecurringFieldsForTask,
  type RecurringOwnFields,
  type TmpSubtask,
  type TmpTask,
} from "@structured/utils/tasks";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

import { TaskDrawerHeader } from "../TaskForm/Components/TaskDrawerHeader";
import { getNewTmpSubtask } from "../TaskForm/getNewTmpSubtask";
import { TaskForm } from "../TaskForm/TaskForm";
import { getNewTmpTask } from "./getNewTmpTask";

export function TaskAddModal(props: {
  onClose: () => void;
  copiedTaskOrRecurring?: Task | RxRecurringNaked;
}) {
  const {
    user: { id: userId },
  } = useSession();
  const { addTaskModalDefaults } = useUI();
  const {
    settings: { theme: themeColor },
  } = useSettings();
  const { addRecurring } = useRecurringTasks();
  const { addTask } = useTasks();
  const [recurringCfg, setRecurringCfg] = useState<RecurringOwnFields | null>(
    null
  );

  const tmpTaskRR = addTaskModalDefaults
    ? { ...getNewTmpTask(), ...addTaskModalDefaults }
    : getNewTmpTask();

  const [tmpTask, setTmpTask] = useState<TmpTask>(
    props.copiedTaskOrRecurring
      ? {
        ...tmpTaskRR,
        ...props.copiedTaskOrRecurring,
        id: tmpTaskRR.id,
        replication_revision: tmpTaskRR.replication_revision,
        _deleted: false,
        color: themeColor ?? DEFAULT_THEME_COLOR,
      }
      : {
        ...tmpTaskRR,
        color: themeColor ?? DEFAULT_THEME_COLOR,
      }
  );
  const [tmpSubtasks, setTmpSubtasks] = useState<TmpSubtask[]>(
    props.copiedTaskOrRecurring?.subtasks && props.copiedTaskOrRecurring.subtasks.length > 0
      ? (props.copiedTaskOrRecurring.subtasks as TmpSubtask[])
      : []
  );

  const updateTmpTask = (updateProps: Partial<TmpTask>) => {
    setTmpTask({
      ...tmpTask,
      ...updateProps,
    });
  };
  const createTaskOrRecurring = () => {
    if (!tmpTask.id) {
      throw new Error(
        "Tmp taskOrRecurring key is needed to created taskOrRecurring with sub tasks"
      );
    }
    if (tmpTask.title.length <= 1) {
      console.warn("Task title length should at least be 1");
      // TODO inform user somehow
      return;
    }
    console.log("Task to be registered", tmpTask);
    if (recurringCfg) {
      void addRecurring({
        ...recurringCfg,
        ...getSharedRecurringFieldsForTask({
          ...tmpTask,
          user_id: userId,
          created_at: new Date().toISOString(),
          modified_at: new Date().toISOString(),
          subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
        }),
      });
    } else {
      void addTask({
        ...tmpTask,
        user_id: userId,
        day: tmpTask.day === null ? dayjs().format("YYYY-MM-DD") : dayjs(tmpTask.day).format("YYYY-MM-DD"),
        created_at: new Date().toISOString(),
        modified_at: new Date().toISOString(),
        subtasks: tmpSubtasks.filter((st) => st.title.length > 0),
      });
    }

    setTmpSubtasks([getNewTmpSubtask()]);
    setTmpTask(getNewTmpTask());
    props.onClose();
  };

  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  const createDynamicTheme = useCallback(
    (theme: Theme) =>
      createTheme({
        ...theme,
        palette: {
          ...theme.palette,
          primary: { main: getColor(tmpTask.color, theme.palette.mode) },
        },
      }),
    [tmpTask.color]
  );

  return (
    <ThemeProvider theme={createDynamicTheme}>
      <Fade in={open}>
        <Box
          onClick={handleClose}
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1200,
          }}
        />
      </Fade>
      <Drawer
        anchor="right"
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDrawer-paper": {
            height: "calc(100% - 3rem)",
            boxSizing: "border-box",
            right: "1.5rem",
            top: "1.5rem",
            bottom: "1.5rem",
            width: "37rem",
            borderRadius: "1rem",
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <TaskDrawerHeader
          focusText="New"
          onClose={handleClose}
        />
        <DottedDivider />
        <Box sx={{
          flexGrow: 1,
          overflowY: "auto",
        }}>
          <TaskForm
            onSubmit={() => createTaskOrRecurring()}
            tmpTask={tmpTask}
            tmpSubtasks={tmpSubtasks}
            recurringCfg={recurringCfg}
            onUpdateTmpTask={updateTmpTask}
            onUpdateTmpSubtasks={setTmpSubtasks}
            onUpdateRecurringCfg={(cfg) =>
              setRecurringCfg((val) => ({ ...val, ...cfg }))
            }
            isEdit={false}
          />
        </Box>
        <Box sx={{
          zIndex: 1,
          position: "sticky",
          bottom: 0,
          marginTop: "auto",
        }}>
          <DottedDivider />
          <Box sx={{
            display: "flex",
            justifyContent: "flex-end",
            padding: "1.5rem"
          }}>
            <Button
              disableElevation
              variant="contained"
              onClick={createTaskOrRecurring}
              sx={{ padding: "0.5rem 1rem" }}
            >
              Create Task
            </Button>
          </Box>
        </Box>
      </Drawer>
    </ThemeProvider>
  );
}