import { DARK_COLOR_MAP, LIGHT_COLOR_MAP } from "../colors.const";
import type { TaskColor, ThemeMode } from "../colors.types";
import { validateHex } from "./validateHex";

export const getColor = (
  color: TaskColor | string,
  theme: ThemeMode = "light"
): string => {
  const toReturn =  (
    (theme === "light"
      ? LIGHT_COLOR_MAP[color as TaskColor]
      : DARK_COLOR_MAP[color as TaskColor]) ?? color
  );
  return validateHex(toReturn, theme);
};
