export const LightTheme = () => (
  <svg viewBox="0 0 104 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="103" height="63" fill="#F9FAF9" />
    <rect
      x="4.52222"
      y="9.11108"
      width="18.087"
      height="4.06349"
      rx="2.03175"
      fill="#F49F99"
    />
    <rect
      x="27.1304"
      y="19.2222"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F49F99"
    />
    <rect
      x="73.4778"
      y="19.2222"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#EBEBEB"
    />
    <rect
      x="73.4778"
      y="29.381"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#EBEBEB"
    />
    <rect
      x="73.4778"
      y="39.5397"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#EBEBEB"
    />
    <rect
      x="73.4778"
      y="49.6985"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#EBEBEB"
    />
    <rect
      x="27.1304"
      y="29.381"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F49F99"
    />
    <rect
      x="27.1304"
      y="39.5397"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F49F99"
    />
    <rect
      x="27.1304"
      y="49.6985"
      width="6.78261"
      height="4.06349"
      rx="2.03175"
      fill="#F49F99"
    />
    <rect
      x="27.1304"
      y="9.11108"
      width="71.2174"
      height="4.06349"
      rx="2.03175"
      fill="#EBEBEB"
    />
    <mask
      id="mask0_830_4194"
      maskUnits="userSpaceOnUse"
      x="74"
      y="34"
      width="24"
      height="24"
    >
      <rect x="74" y="34" width="24" height="24" fill="#D9D9D9" />
    </mask>
  </svg>
);
