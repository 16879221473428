import { Box, Link, Typography } from "@mui/material";
import React from "react";

interface FooterComponentProps {
    footerLinks: { title: string, link: string }[];
    appStoreBadge: string;
    playStoreBadge: string;
    textColor: string;
}

export const FooterComponent = (props: FooterComponentProps) => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
            }}
        >
            <Box sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center", gap: "1.25rem" }}>
                <Typography
                    color={props.textColor}
                    textAlign="center"
                    fontStyle="normal"
                    fontWeight={400}
                    lineHeight="1.5rem"
                >
                    Also available on:
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: "0.75rem", alignSelf: "stretch", justifyContent: "center" }}>
                    <Link href="https://play.google.com/store/apps/details?id=io.unorderly.structured" target="_blank" rel="noreferrer">
                        <Box component="img" src={props.playStoreBadge} alt="Play Store" style={{ width: "8.45rem", height: "2.5rem" }} />
                    </Link>
                    <Link href="https://apps.apple.com/us/app/structured-daily-planner/id1499198946" target="_blank" rel="noreferrer">
                        <Box component="img" src={props.appStoreBadge} alt="App Store" style={{ width: "7.4375rem", height: "2.5rem" }} />
                    </Link>
                </Box>
            </Box>
            <Box sx={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", gap: "1rem", marginBottom: "3.125rem", marginTop: "1.25rem", flexWrap: "wrap", }}>
                {props.footerLinks.map((item, index) => (
                    <React.Fragment key={index}>
                        <Typography
                            textAlign="center"
                            fontSize="0.875rem"
                            fontWeight={400}
                            lineHeight="1rem"
                            color={props.textColor}
                        >
                            <Link href={item.link} underline="hover" color="inherit" target="_blank" rel="noreferrer">
                                {item.title}
                            </Link>
                        </Typography>
                        {index < props.footerLinks.length - 1 && (
                            <span style={{ margin: "0 0.25rem", color: props.textColor }}>|</span>
                        )}
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    )
}