import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { DottedDivider } from "@structured/components/DottedDivider";
import { useUI } from "@structured/store";
import { DEFAULT_TASK_DURATION } from "@structured/utils/common";
import { useFirstFreePeriod } from "@structured/utils/hooks";
import { DEFAULT_TASK_SYMBOL_ID } from "@structured/utils/icons";
import {
  type RecurringOwnFields,
  type TmpSubtask,
  type TmpTask,
} from "@structured/utils/tasks";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Controller, useFieldArray, useForm } from "react-hook-form";

import { ScheduleAndAllDayInputRow, SubTaskInputRow, TaskDurationPickerRow, TaskFormRowContainer, TaskNoteInputRow, TaskTypeInputRow, TimePickerRow } from "./Components";
import TitleInputRow from "./Components/TitleInputRow";
import { getSymbolIdFromTitle } from "./getSymbolIdFromTitle";
import { RecurringOptions } from "./RecurringOptions/RecurringOptions";

export interface TaskFormProps {
  readonly isEdit?: boolean;
  readonly disabled?: boolean;
  readonly tmpTask: TmpTask;
  readonly tmpSubtasks: TmpSubtask[];
  readonly recurringCfg: RecurringOwnFields | null;
  readonly onSubmit: (starTime: number) => void;
  readonly onUpdateTmpTask: (updTmpTask: Partial<TmpTask>) => void;
  readonly onUpdateTmpSubtasks: (updTmpSubtasks: TmpSubtask[]) => void;
  readonly onUpdateRecurringCfg: (
    updRecurring: Partial<RecurringOwnFields> | null
  ) => void;
  readonly onCompleteTmpSubtask?: (
    subtaskId: string,
    completed: boolean
  ) => void;
}

export interface FormValues {
  task: TmpTask;
  recurringCfg: RecurringOwnFields | null;
  subtasks: TmpSubtask[];
}

export const TaskForm: React.FC<TaskFormProps> = (props) => {
  const { selectedDay } = useUI();

  const [isAutoSymbolMode, setIsAutoSymbolMode] = useState<boolean>(
    !props.isEdit
  );
  const [isShowIconPicker, setIsShowIconPicker] = useState<boolean>(false);
  const [taskDuration, setTaskDuration] = useState<number>(props.tmpTask.duration);

  const initialDefaultTime = useFirstFreePeriod(
    selectedDay,
    new Date(),
    props.tmpTask.duration ?? DEFAULT_TASK_DURATION
  );

  const { color, ...defaultValues } = props.tmpTask;

  const { control, handleSubmit, setValue, register, watch } =
    useForm<FormValues>({
      defaultValues: {
        task: {
          ...defaultValues,
          day:
            (props.isEdit && defaultValues.day) ||
            dayjs(initialDefaultTime).format("YYYY-MM-DD"),
          start_time:
            defaultValues.start_time ?? dayjs(initialDefaultTime).hour(),
        },
        recurringCfg: props.recurringCfg,
        subtasks: props.tmpSubtasks,
      },
    });

  const {
    fields,
    update: updateSubtask,
    append: appendSubtask,
    remove: removeSubtask,
  } = useFieldArray({
    control,
    name: "subtasks",
  });

  const task = watch("task");
  const watchSubtasks = watch("subtasks");

  const subtasks = fields.map((field, index) => {
    return {
      ...field,
      ...watchSubtasks[index],
    };
  });

  const onSubmit = handleSubmit(() => {
    props.onSubmit(task.start_time);
  });

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name.startsWith("task") && type === "change") {
        props.onUpdateTmpTask({ ...value.task });
      }

      if (name.startsWith("recurringCfg") && type === "change") {
        props.onUpdateRecurringCfg(
          value.recurringCfg && {
            ...value.recurringCfg,
          }
        );
      }

      if (name.startsWith("subtasks")) {
        props.onUpdateTmpSubtasks(value.subtasks as TmpSubtask[]);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (isAutoSymbolMode) {
      setValue(
        "task.symbol",
        getSymbolIdFromTitle(task.title ?? DEFAULT_TASK_SYMBOL_ID)
      );
    }
  }, [isAutoSymbolMode, task.title]);

  useEffect(() => {
    if (task.is_in_inbox) {
      setValue("task.day", null);
      setValue("task.duration", taskDuration);
    } else {
      if (!task.day) {
        setValue("task.day", dayjs(selectedDay).format("YYYY-MM-DD"));
      }
    }
  }, [task.is_in_inbox, taskDuration]);

  useEffect(() => {
    setValue("task.duration", taskDuration);
  }, [taskDuration]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <form onSubmit={onSubmit} style={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          flexGrow: 1,
          overflowY: "auto",
          padding: "1.5rem 1.5rem 0",
        }}>
          <TitleInputRow
            control={control}
            register={register}
            disabled={props.disabled}
            task={props.tmpTask}
            isShowIconPicker={isShowIconPicker}
            setIsShowIconPicker={setIsShowIconPicker}
            setValue={setValue}
            onUpdateTmpTask={props.onUpdateTmpTask}
          />
          <TaskTypeInputRow disabled={props.disabled} task={task} setValue={setValue} control={control} />
          <DottedDivider />

          {!task.is_in_inbox && (
            <>
              <ScheduleAndAllDayInputRow control={control} disabled={props.disabled} />
              <DottedDivider />
              {!task.is_all_day && (
                <>
                  <TimePickerRow
                    control={control}
                    disabled={props.disabled}
                    task={task}
                    setValue={setValue}
                    taskDuration={taskDuration}
                    setTaskDuration={setTaskDuration}
                  />
                </>
              )}
            </>
          )}
          {(!task.is_all_day || task.is_in_inbox) && (
            <>
              <TaskDurationPickerRow
                disabled={props.disabled}
                task={task}
                setValue={setValue}
                taskDuration={taskDuration}
                setTaskDuration={setTaskDuration}
                control={control}
              />
              <DottedDivider />
            </>
          )}
          {!task.is_in_inbox && (
            <>
              <Controller
                name="recurringCfg"
                control={control}
                disabled={props.disabled}
                render={({ field }) => (
                  <RecurringOptions
                    disabled={props.disabled}
                    initialStartDayIfTask={new Date(task.day)}
                    recurringCfg={field.value}
                    onUpdate={field.onChange}
                  />
                )}
              />
              <DottedDivider />
            </>
          )}
          <SubTaskInputRow
            disabled={props.disabled}
            appendSubtask={appendSubtask}
            updateSubtask={updateSubtask}
            removeSubtask={removeSubtask}
            subtasks={subtasks}
            onCompleteTmpSubtask={props.onCompleteTmpSubtask}
          />
          <DottedDivider />
          <TaskNoteInputRow register={register} disabled={props.disabled} />

        </Box>
      </form>
    </Box>
  );
};