import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import type { IconsByCategoryEntry } from "@structured/utils/icons";
import React from "react";
import { useInView } from "react-intersection-observer";

import styles from "./IconPicker.module.scss";
import { IconPickerEntry } from "./IconPickerEntry";

export interface IconPickerCategoryProps {
  readonly category: IconsByCategoryEntry;
  readonly selectedIcon: string;
  readonly rootRef: Element;
  readonly onSelectIcon: (iconId: string) => void;
  readonly onVisible: () => void;
}

export const IconPickerCategory: React.FC<IconPickerCategoryProps> = (
  props
) => {
  const [ref] = useInView({
    threshold: 0.5,
    root: props.rootRef,
    onChange: (inView) => {
      if (inView) {
        props.onVisible();
      }
    },
  });

  return (
    <Box
      component="section"
      className={styles.IconPicker__Section}
      ref={ref}
      id={props.category.id}
    >
      <Typography  color="text.secondary">
        {props.category.title}
      </Typography>
      <Box className={styles.IconPicker__List}>
        {props.category.icons.map((icon) => (
          <IconPickerEntry
            icon={icon}
            isSelected={icon.id === props.selectedIcon}
            onSelect={() => props.onSelectIcon(icon.id)}
            key={icon.id}
          />
        ))}
      </Box>
    </Box>
  );
};
