import { SCHEMA_VERSIONS } from "../rxdb.const";
import { type TableName } from "../rxdb.types";

export const validateDocs = (
  docs: { schema_version?: number }[],
  tableName: TableName
) =>
  docs.every(
    (doc) => doc.schema_version <= SCHEMA_VERSIONS[tableName].readVersion
  );
