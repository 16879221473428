import { useOccurrences, useRecurringTasks } from "@structured/store";
import dayjs from "dayjs";

import { getDailyRecurringTasks } from "../tasks";

export const useDailyRecurringTasks = (day: Date) => {
  const { recurringTasks } = useRecurringTasks();
  const { occurrences } = useOccurrences();

  return getDailyRecurringTasks(
    recurringTasks,
    occurrences,
    dayjs(day).format("YYYY-MM-DD")
  ).sort((a, b) => (a.localStartTime ?? 0) - (b.localStartTime ?? 0));
};
