import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { type TmpSubtask } from "@structured/utils/tasks";
import React from "react";

export interface AddSubtaskInputProps {
  readonly subtask: TmpSubtask;
  readonly disabled: boolean;
  readonly onDelete: () => void;
  readonly onUpdate: (upd: Partial<TmpSubtask>) => void;
  readonly onComplete: (completed: boolean) => void;
  inputRef?: React.Ref<HTMLInputElement>;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export const AddSubtaskInput: React.FC<AddSubtaskInputProps> = (props) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (props.onKeyDown) {
        props.onKeyDown(event);
      }
    }
  };

  return (
    <Box>
      <TextField
        sx={{ textDecoration: props.subtask.completed_at ? "line-through" : "none" }}
        fullWidth
        placeholder="Task Title"
        InputProps={{
          startAdornment: (
            <Checkbox
              size="small"
              checked={!!props.subtask.completed_at}
              disabled={props.disabled}
              onChange={(_, checked) => props.onComplete(checked)}
            />
          ),
          endAdornment: (
            <IconButton
              size="small"
              disabled={props.disabled}
              onClick={() => props.onDelete()}
            >
              <DeleteOutlineIcon fontSize="inherit" />
            </IconButton>
          ),
        }}
        value={props.subtask.title}
        disabled={props.disabled}
        onChange={(ev) => props.onUpdate({ title: ev.target.value })}
        name="taskTitle"
        inputRef={props.inputRef}
        onKeyDown={handleKeyDown}
      />
    </Box>
  );
};
