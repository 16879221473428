import React, { createContext, useContext, useEffect, useState } from "react";
import { type RxDatabase } from "rxdb";

import { getDatabase } from "./db";
import { type RxAllCollections } from "./rxdb.types";

export interface DatabaseState {
  readonly db: RxDatabase<RxAllCollections>;
  readonly isBooting: boolean;
}

const DatabaseContext = createContext<DatabaseState>({
  db: null,
  isBooting: true,
});

interface DatabaseProviderProps {
  readonly children: React.ReactNode;
}

export const DatabaseProvider: React.FC<DatabaseProviderProps> = ({
  children,
}) => {
  const [db, setDb] = useState<RxDatabase<RxAllCollections>>(null);
  const [isBooting, setIsBooting] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      const instance = await getDatabase();
      setDb(instance);
      setIsBooting(false);
    };

    void initialize();
  }, []);

  return (
    <DatabaseContext.Provider value={{ db, isBooting }}>
      {children}
    </DatabaseContext.Provider>
  );
};

export const useDb = () => {
  return useContext(DatabaseContext);
};
