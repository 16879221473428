import { Box, Grid, Paper, useTheme } from "@mui/material";
import appStoreBadge from "@structured/assets/signup-page-images/appStoreBadge.svg";
import logo from "@structured/assets/signup-page-images/logo.webp";
import playStoreBadge from "@structured/assets/signup-page-images/playStoreBadge.png";
import SignupPageLogo from "@structured/assets/signup-page-images/signup-page-logo.svg";
import React from "react";

import { BETABadgeComponent, FooterComponent, WelcomeToStructuredTextComponent } from "../LoginComponents";

interface DesktopViewProps {
    gradient: string;
    loginForm: React.ReactNode;
    footerLinks: { title: string, link: string }[];
}
const DesktopView = ({ gradient, loginForm, footerLinks }: DesktopViewProps) => {
    const theme = useTheme();
    return (
        <Grid
            item
            xs={6}
            sx={{
                minHeight: "100vh",
                background: gradient,
                position: "relative",
                overflow: "hidden"
            }}

        >
            <Paper elevation={0} sx={{ height: "100vh", borderRadius: "0rem" }}>
                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "end", width: "100%" }}>
                    <BETABadgeComponent />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-evenly", alignItems: "center", height: "100%" }}>
                    <div>
                        <WelcomeToStructuredTextComponent gradient={gradient} logo={logo} />
                    </div>
                    <Box>
                        {loginForm}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FooterComponent
                            footerLinks={footerLinks}
                            appStoreBadge={appStoreBadge}
                            playStoreBadge={playStoreBadge}
                            textColor={theme.palette.text.primary}
                        />
                    </Box>
                </Box>
            </Paper>
        </Grid>
    );
}

export default DesktopView;