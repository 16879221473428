import CloseIcon from "@mui/icons-material/Close";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { ModalWrapper } from "@structured/components";
import { useSettings } from "@structured/store";
import { type ThemeMode } from "@structured/utils/colors";
import { type RxSettingsEntryNaked } from "@structured/utils/rxdb";
import { useState } from "react";

import styles from "./SettingsCmp.module.scss";
import { SubSettingAdvanced } from "./SubSettings/SubSettingAdvanced";
import { SubSettingAppearance } from "./SubSettings/SubSettingAppearance";
import { SubSettingSync } from "./SubSettings/SubSettingSync";

enum TabValue {
  CloudSettings = "Cloud Settings",
  Appearance = "Appearance",
  Advanced = "Advanced",
  HelpAndFeedback = "Help & Feedback",
  Privacy = "Privacy",
}

export const SettingsCmp = (props: { triggerResync: () => void }) => {
  const { settings, themeMode, updateSettings, updateThemeMode } =
    useSettings();

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [debugMenuClickCount, setDebugMenuClickCount] = useState<number>(0);
  const [tab, setTab] = useState<TabValue>(TabValue.CloudSettings);

  const handleSettingsUpdate = (upd: Partial<RxSettingsEntryNaked>) => {
    void updateSettings(settings.user_id, { ...upd });
  };

  const handleThemeModeChange = (mode: ThemeMode) => {
    void updateThemeMode(mode);
  };

  const handleTabChange = (_: React.SyntheticEvent, value: TabValue) => {
    if ([TabValue.Privacy, TabValue.HelpAndFeedback].includes(value)) {
      return;
    }

    setTab(value);
  };

  // const subSettingsContent = {
  //   [SettingsPage.Appearance]: (
  //     <SubSettingAppearance
  //       settings={settings}
  //       onUpdate={(upd) => updateSettings(upd)}
  //     />
  //   ),
  //   [SettingsPage.Advanced]: (
  //     <SubSettingAdvanced
  //       settings={settings}
  //       onUpdate={(upd) => updateSettings(upd)}
  //     />
  //   ),
  //   [SettingsPage.DebugHelper]: (
  //     <SubSettingDebugHelper
  //       settings={settings}
  //       onUpdate={(upd) => updateSettings(upd)}
  //     />
  //   ),
  //   [SettingsPage.Sync]: (
  //     <SubSettingSync
  //       settings={settings}
  //       onUpdate={(upd) => updateSettings(upd)}
  //       triggerResync={() => props.triggerResync()}
  //     />
  //   ),
  // };

  return (
    <>
      <IconButton onClick={() => setIsModalOpen(true)}>
        <SettingsOutlinedIcon />
      </IconButton>
      <ModalWrapper
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        header={
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="subtitle2" fontWeight={600}>
              Settings
            </Typography>
            <IconButton
              size="small"
              onClick={() => setIsModalOpen(false)}
              component="span"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        }
      >
        <Box className={styles.Settings__TabContent}>
          <TabContext value={tab}>
            <TabList onChange={handleTabChange} variant="standard">
              <Tab label="Cloud Settings" value={TabValue.CloudSettings} />
              <Tab label="Appearance" value={TabValue.Appearance} />
              <Tab label="Advanced" value={TabValue.Advanced} />
              <Tab
                label="Help & Feedback"
                href="https://help.structured.app/"
                target="_blank"
                value={TabValue.HelpAndFeedback}
              />
              <Tab
                label="Privacy"
                href="https://structured.app/legal"
                target="_blank"
                value={TabValue.Privacy}
              />
            </TabList>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.CloudSettings}
            >
              <SubSettingSync
                settings={settings}
                triggerResync={() => props.triggerResync()}
              />
            </TabPanel>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.Appearance}
            >
              <SubSettingAppearance
                settings={settings}
                themeMode={themeMode}
                onThemeModeChange={handleThemeModeChange}
                onUpdate={handleSettingsUpdate}
              />
            </TabPanel>
            <TabPanel
              className={styles.Settings__TabForm}
              value={TabValue.Advanced}
            >
              <SubSettingAdvanced
                settings={settings}
                onUpdate={handleSettingsUpdate}
              />
            </TabPanel>
          </TabContext>
        </Box>
      </ModalWrapper>
    </>
  );
};
